.change-profile-mobile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80vh;
  background: linear-gradient(
    180deg,
    rgba(80, 184, 72, 0.5) 63.83%,
    rgba(255, 223, 0, 0.5) 94.44%
  );

  padding: 20px 0;
}

.profile-wrapper-mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 30px;
  column-gap: 50px;
}

.profile-wrapper-mobile .choose-profile-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.profile-wrapper-mobile .choose-profile-mobile .img-circle {
  border-radius: 125.589px;
  border: 4px solid #a2d2e2;
  background: rgb(250, 239, 239) 10% / cover no-repeat;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  height: 100px;
  width: 100px;
  cursor: pointer;
}

.profile-wrapper-mobile .choose-profile-mobile .img-circle.active {
  border: 4px solid #e2dc41;
}

.profile-wrapper-mobile .choose-profile-mobile .profile-name {
  font-weight: 500;
  color: #3c4964;
  font-size: 1rem;
}

@media (max-width: 330px) {
  .profile-wrapper-mobile .choose-profile-mobile .img-circle {
    height: 70px;
    width: 70px;
  }
}
