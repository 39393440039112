.admin-recipe-creation-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(243, 111, 33, 0) 10.21%,
    #50b848 100%
  );
}
.admin-recipe-creation-wrapper h3 {
  margin: 50px 0;
}
.admin-recipe-creation-wrapper .main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin-bottom: 50px;
}

.admin-recipe-creation-wrapper .main form {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 20px; */
}

.admin-recipe-creation-wrapper .main form .input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
  max-width: 400px;
}
.admin-recipe-creation-wrapper .main form .input-wrapper.select {
  margin-bottom: 0;
}
.admin-recipe-creation-wrapper .main form .input-wrapper label {
  font-size: 1rem;
  width: 100%;
  margin-bottom: 5px;
}

.admin-recipe-creation-wrapper .main form .input-wrapper input,
.admin-recipe-creation-wrapper .main form .input-wrapper textarea {
  width: 70vw;
  max-width: 400px;
  height: 40px;
  padding: 0 10px;
  border: none;
  border-bottom: 2px solid #3c4964;
  border-radius: 4px;
  background: #fff;
  font-size: 1rem;
}

.admin-recipe-creation-wrapper .main form .input-wrapper.upload {
  width: 70vw;
  max-width: 400px;
  height: 40px;
  border: none;
  border-bottom: 2px solid #3c4964;
  border-radius: 4px;
  background: #fff;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.admin-recipe-creation-wrapper .main form .input-wrapper.upload div {
  margin-top: 5px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  width: 100%;
}
.admin-recipe-creation-wrapper .main form .input-wrapper .ingredient,
.admin-recipe-creation-wrapper .main form .input-wrapper .step {
  width: 70vw;
  max-width: 400px;
}
.admin-recipe-creation-wrapper .main form .input-wrapper .nutrient {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 70vw;
  max-width: 400px;
  gap: 20px;
}

.admin-recipe-creation-wrapper .main form .input-wrapper textarea {
  height: 80px;
}
.admin-recipe-creation-wrapper .main form .input-wrapper input:focus {
  outline: none;
}
.admin-recipe-creation-wrapper .main form .life-style-choices {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 70%;
  margin-bottom: 20px;
  background-color: #50b848;
}

h5 {
  margin: 10px 0;
}

.admin-recipe-creation-wrapper .main .submit-form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0 50px 0;
}
.admin-recipe-creation-wrapper .main .submit-form button {
  border-radius: 55px;
  background: #f68222;
  border: none;
  color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 12px 50px;
}

#react-select-13-placeholder,
#react-select-11-placeholder,
.css-1dimb5e-singleValue,
.css-1nmdiq5-menu {
  font-size: 1rem;
}
@media (max-width: 400px) {
  #react-select-13-placeholder,
  #react-select-11-placeholder,
  .css-1dimb5e-singleValue,
  .css-1nmdiq5-menu,
  .css-qbdosj-Input {
    font-size: 0.8rem;
  }
}
