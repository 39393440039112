.deleted-account-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.deleted-account-wrapper .logo img {
  width: 25vw;
  margin-bottom: 20px;
}
.deleted-account-wrapper .goodby {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.deleted-account-wrapper .goodby h3 {
  font-size: 2.5rem;
}
.deleted-account-wrapper .goodby p {
  margin: 0;
  padding: 0;
}
