.instagram-container {
  max-width: 600px;
}
.instagram-container .instagram-title {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  color: #3c4964;
}

.instagram-images {
  width: 100vw;
  max-width: 600px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 50px 0;
}

/* mobile */
/* .instagram-images > :first-child,
.instagram-images > :first-child > div,
.instagram-images iframe {
  width: 100vw !important;
  max-width: 560px !important;
  height: auto !important;
  display: flex;
   align-items: center;
  border-radius: 8px;
} */
/* .instagram-images .link-instagram {
  color: #ffffff;
  position: absolute;
  bottom: 20px;
  left: 37%;
} */

.custom-story-container {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.custom-story-content {
  font-family: "Arial", sans-serif;
  font-size: 16px;
  color: #333;
}

div.instagram-container
  > div.instagram-images
  > div
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > span {
  display: flex;
  justify-content: center;
  color: #ffffff;
}
.instagram-container .instagram-title span {
  font-size: 1.2rem;
  font-weight: 500;
}
.instagram-container .instagram-title span .instagram-icon {
  font-size: 30px;
  color: #b89cd1;
}
/* desktop */
.instagram-container .instagram-title.desktop span {
  font-weight: 500;
  font-size: 1.2rem;
}
.instagram-container .instagram-title.desktop span .instagram-icon.desktop {
  font-size: 25px;
}
/* .instagram-images-desktop > :first-child,
.instagram-images-desktop > :first-child > div,
.instagram-images-desktop > :first-child > div span {
  width: 280px !important;
  height: auto !important;
  display: flex;
   align-items: center;
  border-radius: 8px;
} */
iframe::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

iframe::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
}

iframe::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb (scroll handle) */
  border-radius: 6px; /* Rounded corners of the thumb */
}

iframe::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the thumb on hover */
}

/* For Firefox */
iframe {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Thumb and track color */
}

div.instagram-images-desktop
  > div
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
@media (min-width: 1200px) {
  /* .instagram-images-desktop > :first-child,
  .instagram-images-desktop > :first-child > div,
  .instagram-images-desktop > :first-child > div span {
    width: 270px !important;
    height: auto !important;
    display: flex;
    align-items: center;
    border-radius: 8px;
  } */
}
/* @media (min-width: 1101px) {
  .instagram-images-desktop > :first-child,
  .instagram-images-desktop > :first-child > div,
  .instagram-images-desktop > :first-child > div span {
    width: 250px !important;
    height: auto !important;
    display: flex;
    align-items: center;
    border-radius: 8px;
  }
} */
@media (min-width: 800px) and (max-width: 1100px) {
  /* .instagram-images-desktop > :first-child,
  .instagram-images-desktop > :first-child > div,
  .instagram-images-desktop > :first-child > div span {
    width: 180px !important;
    height: 310px !important;
    display: flex;
     align-items: center;
    border-radius: 8px;
  }
  .instagram-container .instagram-title.desktop span {
    font-weight: 500;
    font-size: 0.8rem;
  } */
}
@media (max-width: 620px) {
  /* .instagram-images > :first-child,
  .instagram-images > :first-child > div,
  .instagram-images > :first-child > div span {
    width: 87vw !important;
    height: auto !important;
  }

  .instagram-container .instagram-title span {
    font-size: 0.8rem;
  } */
}
@media (max-width: 450px) {
  /* .instagram-images > :first-child,
  .instagram-images > :first-child > div,
  .instagram-images > :first-child > div span {
    width: 300px !important;
    height: auto !important;
  } */

  /* .instagram-container .instagram-title span {
    font-size: 0.8rem;
  } */
}
/* @media (max-width: 350px) {
  .instagram-images > :first-child,
  .instagram-images > :first-child > div,
  .instagram-images > :first-child > div span {
    width: 200px !important;
    height: auto !important;
  }
} */
