.allergy-disclaimer-container {
  opacity: 0.95;
  background: #3c4964;
  width: 100%;
  min-height: 100vh;
  z-index: 5000;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}
.allergy-disclaimer-container .main {
  background-color: #fff;
  padding: 20px;
  width: 80vw;
  max-width: 350px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.allergy-disclaimer-container .main .disclaimer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.allergy-disclaimer-container .main .disclaimer-wrapper span {
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}

.allergy-disclaimer-container .main .button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.allergy-disclaimer-container .main .button-wrapper button {
  background-color: #d83a30;
  border: none;
  border-radius: 40px;
  padding: 10px 40px;
  color: #fff;
  font-size: 0.8rem;
  cursor: pointer;
}
