.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.carousel-container .carousel-title {
  color: #3c4964;
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0 0 20px 0;
}
.carousel-container .carousel-main {
  display: flex;
  /* justify-content: center; */
  gap: 10px;
  align-items: center;
}
.carousel-container .carousel-main .arrow-left,
.carousel-container .carousel-main .arrow-right {
  cursor: pointer;
}
.carousel-container .carousel-main .arrow-left img,
.carousel-container .carousel-main .arrow-right img {
  width: 30px;
  height: auto;
}

.carousel-container .carousel-main .carousel-content {
  width: 80vw;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 35px 4px 0 0;
}
.carousel-container-desktop .carousel-content {
  position: relative;
  width: 210px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 35px 4px 0 0;
}
/* .carousel-container-desktop .carousel-content {
  max-width: 400px;
} */
.carousel-container .carousel-main .carousel-content .blog-image,
.carousel-container
  .carousel-main
  .carousel-content
  .blog-image
  .blog-main-image {
  width: 100%;
  /* height: 250px; */
  aspect-ratio: 16/9;
  border-radius: 35px 4px 0 0;
  position: relative;
}
.carousel-container
  .carousel-main
  .carousel-content
  .blog-image
  .blog-secondary-image {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 10px;
  bottom: -20px;
  border-radius: 50%;
  z-index: 5;
}
.carousel-container-desktop .carousel-content .blog-avatar-image img {
  border-radius: 50%;
}
.carousel-container .carousel-main .carousel-content .blog-avatar-image {
  position: absolute;
  top: 95px;
  right: 20px;
  z-index: 1;
}

.carousel-container .carousel-main .carousel-content .blog-avatar-image img {
  width: 50px;
  height: 50px;
}

.carousel-container .carousel-main .carousel-content .blog-bottom .blog-title {
  color: #000;
  font-weight: 700;
  font-size: 1rem;
  padding: 20px 20px 0 20px;
}
.carousel-container
  .carousel-main
  .carousel-content
  .blog-bottom
  .blog-content {
  color: #000;
  font-weight: 400;
  font-size: 0.8rem;
  padding: 10px 20px;
}

.carousel-container .carousel-main .carousel-content .blog-bottom .read-more {
  display: flex;
  justify-content: flex-end;
  padding: 0 15px;
  color: #ffffff;
  font-size: 12px;
  cursor: pointer;
}

.carousel-container-desktop {
  width: 35vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.carousel-container-desktop
  .carousel-content
  .blog-image.desktop
  .blog-main-image-desktop {
  width: 100%;
  height: 150px;
  border-radius: 35px 4px 0 0;
  position: relative;
}

.carousel-container-desktop .carousel-content .blog-image.desktop {
  width: 100%;
  height: 120px;
  border-radius: 35px 4px 0 0;
  position: relative;
}
.carousel-container-desktop
  .carousel-content
  .blog-image.desktop
  .blog-secondary-image-desktop {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 10px;
  bottom: -20px;
  border-radius: 50%;
  z-index: 5;
}

.carousel-container .carousel-main .carousel-content .blog-bottom {
  background-color: #f8c13b;
  width: 100%;
  /* Add this CSS code to create a zigzag effect on blog-bottom */
  --mask: conic-gradient(
      from -32.5deg at bottom,
      #0000,
      #000 1deg 59deg,
      #0000 60deg
    )
    50%/22px 100%;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  padding-bottom: 40px;
  height: 250px;
}

.carousel-container-desktop .carousel-content .blog-bottom.desktop {
  background-color: #f8c13b;
  width: 100%;
  /* Add this CSS code to create a zigzag effect on blog-bottom */
  --mask: conic-gradient(
      from -32.5deg at bottom,
      #0000,
      #000 1deg 59deg,
      #0000 60deg
    )
    50%/22px 100%;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  padding-bottom: 40px;
  height: 250px;
}
.carousel-container-desktop .carousel-content .blog-bottom.desktop {
  padding: 20px 0 30px 0;
}

.carousel-container-desktop .carousel-content .blog-bottom .blog-title.desktop {
  color: #000;
  font-weight: 700;
  font-size: 1rem;
  padding: 0 20px 0 20px;
  height: 60px;
}

.carousel-container .carousel-main .carousel-content .blog-bottom .line,
.carousel-container-desktop .carousel-content .blog-bottom .line {
  width: 80%;
  margin: 5px auto;
  height: 1px;
  background-color: #ffffff;
}

.carousel-container-desktop
  .carousel-content
  .blog-bottom
  .blog-content.desktop {
  color: #000;
  font-size: 0.7rem;
  padding: 5px 20px 10px 20px;
  height: 120px;
}

.carousel-container-desktop .carousel-content .blog-bottom .read-more.desktop {
  display: flex;
  justify-content: flex-end;
  padding: 0 15px;
  color: #ffffff;
  font-size: 0.8rem;
  cursor: pointer;
}

.carousel-container
  .carousel-main
  .carousel-content
  .blog-bottom
  .read-more
  .go-to-post,
.carousel-container-desktop
  .carousel-content
  .blog-bottom
  .read-more
  .go-to-post {
  text-decoration: underline;
  text-underline-offset: 3px;
}

.admin-article {
  position: relative;
  bottom: 180px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  padding: 0 5px;
  cursor: pointer;
  /* margin-top: 20px; */
}
.admin-article.mobile {
  bottom: 0;
  top: 60px;
}
@media (min-width: 800px) {
  .carousel-container-desktop
    .carousel-content
    .blog-bottom
    .blog-title.desktop {
    font-size: 0.6rem;
    padding: 0 20px 0 10px;
  }

  .carousel-container-desktop
    .carousel-content
    .blog-bottom
    .blog-content.desktop {
    font-size: 0.6rem;
    padding: 5px 10px 10px 10px;
  }
  .carousel-container-desktop
    .carousel-content
    .blog-bottom
    .read-more.desktop {
    justify-content: center;
    padding: 0 10px;
  }

  .carousel-container-desktop
    .carousel-content
    .blog-bottom
    .read-more.desktop
    span {
    display: flex;
    align-items: center;
    font-size: 0.5rem;
  }
  .carousel-container-desktop
    .carousel-content
    .blog-bottom
    .read-more.desktop
    span
    .arrow-icon {
    font-size: 0.8rem;
    margin-bottom: 2px;
  }
}
@media (min-width: 1100px) {
  .carousel-container-desktop {
    width: 40vw;
    gap: 20px;
  }
  .carousel-container-desktop
    .carousel-content
    .blog-bottom
    .blog-title.desktop {
    font-size: 0.7rem;
    text-align: center;
  }
  .carousel-container-desktop
    .carousel-content
    .blog-bottom
    .blog-content.desktop {
    font-size: 0.8rem;
    padding: 5px 20px 10px 20px;
  }

  .carousel-container-desktop
    .carousel-content
    .blog-bottom
    .read-more.desktop {
    justify-content: flex-end;
    padding: 0 10px;
  }

  .carousel-container-desktop
    .carousel-content
    .blog-bottom
    .read-more.desktop
    span {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
  }
  .carousel-container-desktop
    .carousel-content
    .blog-bottom
    .read-more.desktop
    span
    .arrow-icon {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 4px;
  }
}
@media (max-width: 500px) {
  .carousel-container .carousel-main .carousel-content {
    width: 70vw;
  }
}
