.edit-profile-container {
  padding: 0 20px 50px 20px;
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../images/mobile/back-flowers2.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.edit-profile-container h2 {
  font-weight: 500;
  color: #3c4964;
  font-size: 1.3rem;
  margin-top: 50px;
  width: auto;
}

.edit-profile-container.desktop h2 {
  font-weight: 500;
  color: #3c4964;
  font-size: 1.5rem;
  width: 90%;
  margin-top: 50px;
}
.edit-profile-container .change-avatar {
  background-image: url("../../images/pic-frame.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}
.edit-profile-container .change-avatar .img-circle {
  border-radius: 150.589px;
  border: 4px solid #a2d2e2;
  background: rgb(250, 239, 239) 10% / cover no-repeat;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 100px;
  width: 100px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-profile-container .change-avatar .text {
  color: #3c4964;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.edit-profile-container .profile-selections {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.edit-profile-container .profile-selections .select-reveal {
  width: 90vw;
  max-width: 600px;
  /* padding: 30px 20px 0 20px; */
  display: flex;
  flex-direction: column;
  /* padding-top: 30px; */
  align-items: center;
  padding: 15px 30px 0 30px;
  border-radius: 6px 6px 0px 0px;
  background: #e0eef2;
  margin-bottom: 13px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  min-height: none;
  /* height: 60px; */
}
.edit-profile-container .profile-selections .select-reveal.last {
  --mask: conic-gradient(
      from -35deg at bottom,
      #0000,
      #000 1deg 69deg,
      #0000 70deg
    )
    50%/28.01px 100%;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}
.edit-profile-container
  .profile-selections
  .select-reveal.last::-webkit-scrollbar {
  width: 5px;
}
.edit-profile-container
  .profile-selections
  .select-reveal.last::-webkit-scrollbar-track {
  background: transparent;
}
.edit-profile-container
  .profile-selections
  .select-reveal.last::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}
.edit-profile-container
  .profile-selections
  .select-reveal.last
  .css-1mndia5-menu {
  margin-bottom: 10px;
}
.edit-profile-container .profile-selections .select-reveal.last:last-child {
  margin-bottom: 50px;
}

.edit-profile-container .profile-selections .select-reveal .top {
  display: flex;
  align-items: center;
  padding: 0 15px 20px 15px;
  width: 100%;
  font-size: 0.8rem;
}
.edit-profile-container .profile-selections .select-wrapper {
  background: #e0eef2;
  width: 50vw;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
/* name input */
.edit-profile-container .profile-selections .select-wrapper .changeName {
  border-radius: 6px;
  border: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: #ffffff;
  height: 30px;
  width: 100%;
  max-width: 280px;
  font-size: 1rem;
}
.edit-profile-container .profile-selections .select-wrapper button {
  background: #f68222;
  color: #fff;
  border: none;
  border-radius: 55px;
  padding: 8px 20px;
  cursor: pointer;
  font-size: 1rem;
  margin: 15px 0 10px 0;
}

.edit-profile-container
  .profile-selections
  .select-wrapper
  .edit-profile-container
  .profile-selections
  .select-wrapper
  .css-qbdosj-Input,
.edit-profile-container
  .profile-selections
  .select-wrapper
  .css-18yvwva-placeholder {
  font-size: 0.8rem;
}
.edit-profile-container .profile-selections .select-wrapper .css-1nmdiaq5-menu {
  font-size: 0.8rem;
}
.edit-profile-container .profile-selections .select-reveal .pre-selected {
  /* width: 50vw; */
  max-width: 280px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.edit-profile-container .profile-selections .select-reveal .pre-selected .item {
  border-radius: 8px;
  background: #3aa7d5;
  display: flex;
  padding: 6px 15px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 0.9rem;
}
.edit-profile-container .button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 90vw;
  max-width: 600px;
}

.edit-profile-container .button-wrapper .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 55px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  padding: 17px 40px;
  min-width: 30%;
  font-size: 1rem;
  cursor: pointer;
}
.edit-profile-container .button-wrapper .submit-profile-changes {
  background: #d83a30;
}
.edit-profile-container .button-wrapper .back {
  background: #3aa7d5;
}
.edit-profile-container .button-wrapper .delete-btn {
  background: #f68222;
}

/* desktop  */

/* Desktop */
.edit-profile-container .button-wrapper.desktop {
  gap: 50px;
  margin-top: 50px;
}
.edit-profile-container .button-wrapper.desktop .btn {
  padding: 12px 55px;
}
.edit-profile-container .button-wrapper.desktop .submit-profile-changes,
.edit-profile-container .button-wrapper.desktop .back,
.edit-profile-container .button-wrapper.desktop .delete-btn {
  padding: 15px 80px;
  /* width: 40vw;
  max-width: 350px; */
  font-size: 1.2rem;
}

.edit-profile-container.desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../images/desktop/back-flowers-desktop.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.edit-profile-container.desktop .profile-selections.desktop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  align-items: baseline;
}

.edit-profile-container.desktop
  .profile-selections.desktop
  .select-reveal.desktop {
  width: 20vw;
  max-width: 280px;
  min-height: 280px;
  margin-top: 20px;
}
/* change language mobile */
.edit-profile-container .change-language {
  width: 90vw;
  max-width: 600px;
  background-color: #e0eef2;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 50px 0 15px 0;
  height: 60px;
  justify-content: space-between;
}
.edit-profile-container .change-language span {
  font-size: 1rem;
}
.css-1y8l5qs-control {
  border-color: transparent !important;
  box-shadow: none !important;
}
/* change language desktop */
.edit-profile-container.desktop .change-language.desktop {
  width: 20vw;
  max-width: 280px;
  background-color: #e0eef2;
  padding: 15px 15px 20px 15px;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 50px;
  height: 120px;
}
.edit-profile-container.desktop .change-language.desktop span {
  padding: 0 15px 20px 15px;
  width: 100%;
  text-align: center;
  font-size: 1rem;
}

.edit-profile-container.desktop .change-language.desktop .css-b62m3t-container {
  width: 100%;
}

.edit-profile-container.desktop .select-reveal.last.desktop {
  --mask: none; /* Remove the conic gradient mask */
  -webkit-mask: none; /* Remove the mask for webkit browsers */
  mask: none; /* Remove the mask for non-webkit browsers */
  border: none; /* Remove any additional styling */
}

.edit-profile-container.desktop
  .profile-selections.desktop
  .select-reveal.desktop
  .select-wrapper.desktop,
.edit-profile-container.desktop
  .profile-selections.desktop
  .select-reveal.desktop
  .select-wrapper.desktop
  .css-b62m3t-container {
  width: 18vw;
  max-width: 200px;
  display: flex;
  justify-content: center;
}
@media (max-width: 400px) {
  .edit-profile-container .profile-selections .select-wrapper button,
  .edit-profile-container .profile-selections .select-wrapper .changeName {
    font-size: 0.8rem;
  }
  .edit-profile-container .button-wrapper {
    flex-direction: column;
    gap: 20px;
    margin-top: 0;
  }

  .edit-profile-container .button-wrapper .btn {
    font-size: 1rem;
    width: 90vw;
  }
  .edit-profile-container
    .profile-selections
    .select-reveal
    .pre-selected
    .item {
    padding: 6px 15px;

    font-size: 0.7rem;
  }
  /* .edit-profile-container.desktop .change-language.desktop span {
    padding: 0 15px 20px 15px;
    width: 100%;
    text-align: center;
    font-size: 1rem;
  } */
  .edit-profile-container .change-language span {
    font-size: 0.8rem;
  }
}

@media (min-width: 401px) {
  .edit-profile-container .button-wrapper {
    flex-direction: row;
  }
  .edit-profile-container
    .profile-selections
    .select-reveal
    .pre-selected-foods
    .item {
    font-size: 0.8rem;
  }
  .edit-profile-container .profile-selections .select-reveal .top {
    font-size: 1rem;
  }
  .edit-profile-container .profile-selections .select-wrapper .css-qbdosj-Input,
  .edit-profile-container
    .profile-selections
    .select-wrapper
    .css-18yvwva-placeholder {
    font-size: 1rem;
  }
  .edit-profile-container
    .profile-selections
    .select-wrapper
    .css-1nmdiaq5-menu {
    font-size: 1rem;
  }
  .edit-profile-container .change-avatar .text {
    font-size: 1rem;
  }
}
@media (min-width: 500px) {
  .edit-profile-container
    .profile-selections
    .select-reveal
    .pre-selected-foods
    .item {
    font-size: 1rem;
  }
}
@media (min-width: 800px) {
  .edit-profile-container .change-avatar .img-circle {
    height: 160px;
    width: 160px;
  }
}
