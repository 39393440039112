.delete-article-popup-container {
  opacity: 0.95;
  background: #3c4964;
  width: 100%;
  min-height: 100vh;
  z-index: 5000;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}
.delete-article-main {
  background-color: #fff;
  padding: 20px;
  width: 80vw;
  max-width: 450px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 140px;
  justify-content: center;
}
.delete-article-main .delete-article-buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.delete-article-main .delete-article-buttons button {
  background-color: #d83a30;
  border: none;
  border-radius: 40px;
  padding: 10px 40px;
  color: #fff;
  font-size: 0.8rem;
  cursor: pointer;
}
.delete-article-main .delete-article-buttons button.no {
  background-color: grey;
}
