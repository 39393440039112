.delete-recipe-popup-container {
  opacity: 0.95;
  background: #3c4964;
  width: 100%;
  min-height: 100vh;
  z-index: 5000;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}
.delete-recipe-popup-container .delete-recipe-main {
  background-color: #fff;
  padding: 20px;
  width: 80vw;
  max-width: 350px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.delete-recipe-popup-container .delete-recipe-main p {
  text-align: center;
}
.delete-recipe-popup-container .delete-recipe-main .delete-recipe-buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.delete-recipe-popup-container
  .delete-recipe-main
  .delete-recipe-buttons
  .delete-recipe {
  background-color: #d83a30;
  border: none;
  border-radius: 40px;
  padding: 10px 40px;
  color: #fff;
  font-size: 0.8rem;
  cursor: pointer;
}
.delete-recipe-popup-container
  .delete-recipe-main
  .delete-recipe-buttons
  .delete-recipe.no {
  background-color: grey;
}

@media (max-width: 330px) {
  .delete-recipe-popup-container .delete-recipe-main p {
    font-size: 0.8rem;
  }
  .delete-recipe-popup-container
    .delete-recipe-main
    .delete-recipe-buttons
    .delete-recipe {
    padding: 10px 20px;
    font-size: 0.7rem;
  }
}
