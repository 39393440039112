.home-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  /* padding: 0 10px; */
  position: relative;
  /* margin-bottom: 80px; */
}

.home-page-container.no-events {
  pointer-events: none;
}

/*  mobile  view */

.home-page-container .notifications-wrapper {
  /* margin-top: 30px; */
  /* padding: 0 20px; */
}
.home-page-container .notifications-wrapper > *:not(:last-child) {
  margin-bottom: 30px;
}
.home-page-container .home-page-images {
  width: 100vw;
  max-width: 600px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
}

.home-page-container .home-page-images .lollipop {
  position: relative;
  top: 60px;
  width: 38%;
}
.home-page-container .home-page-images .vegetables {
  position: relative;
  top: 10px;
  left: 20px;
  width: 30%;
}
.home-page-container .visit-blog {
  display: flex;
  padding: 12px 30px;
  width: 85vw;
  max-width: 560px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 55px;
  border: 1px solid #f68222;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #f68222;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 500;
}
/*  desktop  view */

.home-page-desktop-wrapper {
  width: 95%;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}
.home-page-desktop-wrapper .home-page-desktop-profile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  width: 30%;
}
.home-page-desktop-wrapper .no-suggestions-desktop {
}
.home-page-desktop-wrapper .desktop-separator {
  margin-right: 10px;
  width: 1px;
  min-height: 60vh;
  background-color: #dfdfdf;
}

.home-page-desktop-wrapper .home-page-desktop-activities {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* gap: 20px; */
  width: 70%;
}
.home-page-desktop-wrapper .home-page-desktop-activities .top-row,
.home-page-desktop-wrapper .home-page-desktop-activities .middle-row {
  display: flex;
  justify-content: center;
  width: 100%;
  /* min-width: 600px; */
  align-items: flex-start;
  margin-top: 20px;
}

/* .home-page-desktop-wrapper .home-page-desktop-activities .middle-row {
  margin-bottom: 20px;
} */

.home-page-desktop-wrapper .home-page-desktop-activities .bottom-row {
  display: flex;
  justify-content: center;
  gap: 40px;
  width: 70vw;
  align-items: center;
  margin: 20px 0 50px 0;
}
/* @media (min-width: 1100px) {
  .home-page-desktop-wrapper .home-page-desktop-activities .middle-row {
    padding: 0;
  }
} */

@media (min-width: 250px) and (max-width: 450px) {
  .home-page-container .home-page-images img {
    width: 30%;
  }
  .home-page-container .btn-wrapper {
    justify-content: center;
    gap: 20px;
  }

  .home-page-container .visit-blog {
    justify-content: center;
    gap: 30px;
    font-size: 0.9rem;
  }
}

@media (min-width: 1200px) {
  /* .home-page-desktop-wrapper .home-page-desktop-activities .top-row {
    justify-content: space-evenly;
  } */
  .home-page-desktop-wrapper .home-page-desktop-activities .bottom-row {
    gap: 20px;
  }
}
