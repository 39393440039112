.mobile-footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.mobile-footer-container .strawberries {
  max-width: 250px;
  width: 100%;
  z-index: 1;
  position: relative;
  top: 20px;
}
.mobile-footer-container .strawberries img {
  width: 100%;
}
.mobile-footer-container .main-mobile-footer {
  background: #efefef;
  border-radius: 25px 25px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mobile-footer-container .main-mobile-footer .mobile-footer-title {
  padding-top: 30px;
  color: #3c4964;
  font-weight: 500;
  font-size: 1.25rem;
}
.mobile-footer-container .main-mobile-footer .separator {
  width: 80%;

  border-bottom: 4px dotted #3c4964;
  opacity: 0.2;
  margin: 20px auto;
}
.mobile-footer-container .main-mobile-footer .mobile-footer-buttons {
  display: flex;
  /* width: 100%; */
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  padding: 0 20px;
}

.mobile-footer-container .main-mobile-footer .mobile-footer-buttons div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 0 10px; */
}
.mobile-footer-container .main-mobile-footer .mobile-footer-buttons div.videos,
.mobile-footer-container
  .main-mobile-footer
  .mobile-footer-buttons
  div.premium {
  margin-bottom: 20px;
}

.mobile-footer-container
  .main-mobile-footer
  .mobile-footer-buttons
  div
  .footer-circle {
  background-color: #b89cd1;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-footer-container
  .main-mobile-footer
  .mobile-footer-buttons
  div
  div
  img {
  width: 40px;
  height: auto;
}
.mobile-footer-container
  .main-mobile-footer
  .mobile-footer-buttons
  div
  div
  img.clean {
  width: 30px;
  height: auto;
}
.mobile-footer-container
  .main-mobile-footer
  .mobile-footer-buttons
  div
  .footer-link {
  color: #3c4964;
  font-size: 1rem;
  margin: 5px 0 20px 0;
  width: 30vw;
  text-align: center;
}

.mobile-footer-container .footer-credit {
  cursor: pointer;
  width: 100%;
  position: relative;
  background-color: #3c4964;
  color: #ffffff;
  font-size: 0.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 25px 40px;
}
.mobile-footer-container .footer-credit .icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: initial;
}
.mobile-footer-container .footer-credit .icons div .footer-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.mobile-footer-container .footer-credit .icons .blog img {
  width: 35px;
  height: auto;
}
@media (max-width: 475px) {
  .mobile-footer-container .footer-credit {
    flex-direction: column;
    padding: 20px 10px 10px 10px;
  }
  .mobile-footer-container .footer-credit .icons {
    justify-content: space-evenly;
    width: 100%;
    gap: 10px;
  }

  .mobile-footer-container .main-mobile-footer .mobile-footer-buttons {
    padding: 0 10px;
    /* gap: 20px; */
  }

  .mobile-footer-container
    .main-mobile-footer
    .mobile-footer-buttons
    div
    .footer-circle {
    width: 60px;
    height: 60px;
  }

  .mobile-footer-container
    .main-mobile-footer
    .mobile-footer-buttons
    div
    div
    img {
    width: 35px;
    height: auto;
  }
  .mobile-footer-container
    .main-mobile-footer
    .mobile-footer-buttons
    div
    div
    img.clean {
    width: 25px;
    height: auto;
  }
  .mobile-footer-container
    .main-mobile-footer
    .mobile-footer-buttons
    div
    .footer-link {
    font-size: 0.8rem;
    margin: 5px 0 20px 0;
  }
}
@media (max-width: 330px) {
  .mobile-footer-container
    .main-mobile-footer
    .mobile-footer-buttons
    div
    .footer-link {
    width: 25vw;
  }
  .mobile-footer-container .main-mobile-footer .mobile-footer-buttons {
    gap: 10px;
  }

  .mobile-footer-container
    .main-mobile-footer
    .mobile-footer-buttons
    div.premium {
    margin-bottom: 0;
  }
}
@media (max-width: 300px) {
  .mobile-footer-container
    .main-mobile-footer
    .mobile-footer-buttons
    div
    .footer-link {
    font-size: 0.7rem;
  }
}
