/* mobile and desktop */
.videos-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 76vh;
  width: 100%;
  margin: 30px 0 50px 0;
  padding: 0 60px;
}

/* MOBILE */

.videos-wrapper .filter-wrapper {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 50px 0;
  margin: 10px 0;
  background-color: #e0eef2;
  border-radius: 6px 6px 0px 0px;
  --mask: conic-gradient(
      from -32.5deg at bottom,
      #0000,
      #000 1deg 64deg,
      #0000 65deg
    )
    50%/22px 100%;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  padding-bottom: 50px;
}
.videos-wrapper .filter-wrapper .filter-modal {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 0 10px;
}
.videos-wrapper .filter-wrapper .filter-modal h4 {
  font-size: 2rem;
  color: #3c4964;
}
.videos-wrapper .filter-wrapper .filter-options {
  width: 100%;
  margin: 20px 0;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.videos-wrapper .filter-wrapper .filter-options div {
  display: flex;
  justify-content: flex-start;
  /* padding-left: 22%; */
  align-items: center;
  gap: 10px;
}
.videos-wrapper .filter-wrapper .filter-options div input {
  width: 15px;
  height: 15px;
}
.videos-wrapper .filter-wrapper .filter-options div label {
  color: #3c4964;
  font-size: 1.2rem;
}
.videos-wrapper .error {
  color: #e90c0c;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.videos-wrapper .videos {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin: 50px auto 0 auto;
}
/* DESKTOP */
.videos-wrapper .title.desktop {
  color: #3c4964;
  width: 73vw;
  margin: 0 auto;
}
.videos-wrapper .filters-wrapper.desktop {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #e0eef2;
  height: 70px;
  width: 73vw;
  margin: 20px auto;
}
.videos-wrapper .filters-wrapper.desktop h4 {
  color: #3c4964;
}
.videos-wrapper .filters-wrapper.desktop div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #3c4964;
}
.videos-wrapper .error-desktop {
  color: #e90c0c;
  width: 73vw;
  margin: 0 auto;
  text-align: center;
}
.videos-wrapper .videos-desktop {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 73vw;
  margin: 20px auto 50px;
}
.video-card {
  padding: 5px;
  border-radius: 5px;
  background-color: #e0eef2;
  cursor: pointer;
}
/* mobile */
.video-card .video-image-wrapper img {
  width: 100%;
  height: 220px;
}
/* desktop */
.video-card .video-image-wrapper.desktop img {
  width: 100%;
  height: 150px;
}
.video-card .admin-panel {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}
.video-card .video-title {
  /* padding-left: 10px; */
  font-size: 1.5rem;
  font-weight: 500;
  color: #3c4964;
  margin: 10px 0;
}
.video-card .video-category {
  /* padding-left: 10px; */
  font-size: 1.1rem;
  color: #3c4964;
  margin: 10px 0;
}
.video-card .video-description {
  /* padding-left: 10px; */
  font-size: 1rem;
  color: #3c4964;
  margin: 10px 0;
}
@media (max-width: 480px) {
  .videos-wrapper .filter-wrapper .filter-modal h4 {
    font-size: 1.5rem;
    color: #3c4964;
  }

  .videos-wrapper .filter-wrapper .filter-options div label {
    font-size: 1rem;
  }

  .video-card .video-image-wrapper img {
    height: auto;
  }
}

@media (max-width: 390px) {
  .videos-wrapper .filter-wrapper .filter-modal h4 {
    font-size: 1.2rem;
    color: #3c4964;
  }
  .videos-wrapper .filter-wrapper .filter-options {
    width: 100%;
    margin: 20px 0;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .videos-wrapper .filter-wrapper .filter-options div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
  }

  .videos-wrapper .filter-wrapper .filter-options div label {
    font-size: 1.1rem;
  }
}
@media (max-width: 320px) {
  .videos-wrapper .filter-wrapper .filter-options div {
    padding-left: 0;
  }
  .video-card .video-title {
    font-size: 1.1rem;
    margin: 5px 0;
  }
  .video-card .video-category {
    font-size: 0.9rem;
    margin: 5px 0;
  }
  .video-card .video-description {
    font-size: 0.8rem;

    margin: 5px 0 10px 0;
  }
}
