.weekly-recipes-popup-container {
  background-color: #3c4964;
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.weekly-recipes-popup-container .close-popup-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.weekly-recipes-popup-container .close-popup-wrapper .close {
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 55px;
  border: 1px solid #3aa7d5;
  color: #3aa7d5;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 0.9rem;
  padding: 7px 18px;
  width: 150px;
}
