.user-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll on the container */
}

.user-list-main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 90%; /* Take 90% of the view */
  max-width: 1400px; /* Optional: set a max-width for very large screens */
}
.user-list-main-content .search-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.user-list-main-content .search-wrapper h1,
.user-list-main-content .search-wrapper .search {
  margin-top: 50px;
  width: 50%;
}
.user-list-main-content .search-wrapper .search {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.user-list-main-content .search-wrapper .search input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 35px;
  width: 70%;
}
.user-list-main-content .search-wrapper .search button {
  background-color: #4db848;
  color: #fff;
  padding: 5px 20px;
  border: none;
  border-radius: 55px;
  cursor: pointer;
}
.user-list-main-content .search-wrapper .search button.disabled {
  background-color: #95a594;
  color: #fff;
  cursor: not-allowed;
}
.searching-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  height: 50vh;
}
/* Table container with horizontal scroll */
.table-container {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 50px;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  min-width: 1200px; /* Ensure table doesn't shrink too much */
  overflow-x: scroll; /* Enable horizontal scroll */
}

/* Style for table headers */
.users-table th {
  background-color: #f2f2f2;
  padding: 12px 16px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Style for table cells */
.users-table td {
  padding: 12px 16px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  text-align: left; /* Align with headers */
}

/* Alternate row color for better readability */
.users-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Hover effect for rows */
.users-table tbody tr:hover {
  background-color: #f5f5f5;
}

/* RTL support */
[dir="rtl"] .users-table th,
[dir="rtl"] .users-table td {
  text-align: right;
}
.pagination-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 10px 0 10px;
}
.pagination-buttons button {
  padding: 10px 20px;
  background-color: #f68222;
  color: #fff;
  cursor: pointer;
  padding: 15px 80px;
  border-radius: 55px;
  border: none;
  width: 20%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination-buttons button.previous-button {
  background-color: #3aa7d5;
}
