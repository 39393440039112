.search-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: 95vh;
}
.search-container .title {
  font-weight: 500;
  font-size: 1.5rem;
  padding: 50px 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
/* .search-steps .start-search {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
} */
.search-steps .start-search img {
  width: 50%;
}

.search-steps .buttons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}
.search-steps .buttons-wrapper button {
  padding: 13px 50px;
  border-radius: 55px;
  background: #f68222;
  color: #fff;
  border: none;
  font-size: 1rem;
  font-weight: 500;
}
.search-steps .buttons-wrapper button.disabled {
  background: #d3d3d3;
  cursor: default;
}

.search-steps .submit-search.disabled button {
  background: #d3d3d3;
  border: none;
  cursor: default;
}
.search-steps .error {
  color: #d83a30;
  font-size: 1rem;
  font-weight: 500;
  padding: 0 20px;
}

.search-steps .select-wrapper {
  position: relative;
  display: inline-block;
  width: 70vw;
}

.search-steps .select-wrapper select {
  appearance: none;
  width: 100%;
  border-radius: 4px;
  border: none;
  border-bottom: 2px solid #3c4964;
  background: rgba(162, 210, 226, 0.3);
  height: 50px;
  padding-inline-start: 20px;
}
.search-steps .select-wrapper select:focus {
  outline: none;
}
.search-steps .select-wrapper select option {
  background-color: #f36f21;
  margin: 5px 0;
  border-radius: 0;
  color: #fff;
}
/* .search-steps .select-wrapper select option:hover {
  background-color: rgba(162, 210, 226, 1);
} */
.search-steps .select-wrapper select option:checked {
  background-color: #007cc4;
}
.select-arrow {
  position: relative; /* Use relative positioning */
  display: inline-block;
  cursor: pointer;
  top: 50%;
  transform: translateY(-150%);
}

.select-arrow span {
  font-size: 12px;
}

/* Position arrow based on language direction */

.search-steps .cooking-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.search-steps .cooking-wrapper label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
/* Remove default styles for range input */
.search-steps .cooking-wrapper label input[type="range"] {
  -webkit-appearance: none; /* Remove default styles for Webkit browsers */
  appearance: none; /* Remove default styles for other browsers */
  background: #007cc4; /* Remove background */
  border: none; /* Remove border */
  width: 70vw;
  max-width: 290px;
  height: 3px; /* Set the height as needed */
  margin-top: 10px; /* Optionally, adjust margin as needed */
  padding: 0; /* Optionally, adjust padding as needed */
}

/* Style the thumb (the draggable handle) */
.search-steps .cooking-wrapper label input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default styles for Webkit browsers */
  appearance: none; /* Remove default styles for other browsers */
  width: 20px; /* Set the width of the thumb as needed */
  height: 20px; /* Set the height of the thumb as needed */
  background-color: #007cc4; /* Set the thumb's background color */
  border: none; /* Remove border for the thumb */
  border-radius: 50%; /* Make the thumb round */
  cursor: pointer; /* Change cursor to pointer */
}
.search-steps .cooking-wrapper label div {
  color: #007cc4;
}

.allergies-search {
  background-color: #e3f1f6;
  width: 70vw;
  /* max-width: 290px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}
.allergies-search h2 {
  padding: 10px 0;
  font-size: 1.2rem;
}
.allergies-search .allergies-display {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 0 10px;
  flex-wrap: wrap;
}

.allergies-search .allergies-display .allergy {
  border-radius: 8px;
  padding: 2px 5px;
  background-color: #3aa7d5;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #fff;
  font-size: clamp(0.8rem, 1vw, 1rem);
  cursor: pointer;
}
.allergies-search .allergies-display .allergy .close-icon {
  margin-bottom: 3px;
}
@media (max-width: 420px) {
  .search-steps .buttons-wrapper {
    flex-direction: column;
  }
  .search-steps .buttons-wrapper button {
    width: 70vw;
  }
  /* .search-steps .buttons-wrapper button {
    padding: 10px 30px;
  } */
}
