.profile-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 0 20px;
}
.profile-box.desktop {
  width: 22vw;
}
.profile-box img {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
}

.profile-box.desktop img {
  width: 100%;
  max-width: 220px;
}

.profile-box .profile-name {
  font-size: 1.3rem;
  font-weight: 500;
  color: #3c4964;
  margin-top: 30px;
}
.profile-box .profile-name h2 {
  font-size: 2.3rem;
  font-weight: 500;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}
/* mobile btn */
.btn-wrapper {
  cursor: pointer;
  display: flex;
  width: 92vw;
  max-width: 560px;
  height: 58px;
  padding: 20px 61px;
  margin: 10px 0;
  justify-content: center;
  align-items: center;
  gap: 40px;
  border-radius: 55px;
  background: #f8c13b;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-weight: 500;
}
.buttons-container .kids-zone.mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../images//Untitled-3.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 55px;
  color: #ffffff;
  height: 58px;
  font-size: 1.3rem;
  margin-bottom: 50px;
  font-weight: 500;
}
/* desktop btn */

.btn-wrapper-desktop {
  display: flex;
  max-width: 250px;
  height: 40px;
  padding: 7px 30px;
  margin: 10px 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 55px;
  background: #f8c13b;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  font-weight: 500;
}
.btn-wrapper span,
.btn-wrapper .arrow-icon {
  color: #3c4964;
  font-size: 1.3rem;
}
.btn-wrapper-desktop span,
.btn-wrapper-desktop .arrow-icon {
  color: #3c4964;
  font-size: 0.8rem;
}

.buttons-container .kids-zone {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../images//Untitled-3.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 55px;
  color: #ffffff;
  height: 40px;
  margin-bottom: 10px;
}
@media (min-width: 1100px) {
  /* .btn-wrapper-desktop {
    padding: 20px 60px;
  } */

  .btn-wrapper-desktop span,
  .btn-wrapper-desktop .arrow-icon {
    font-size: 0.8rem;
  }
}
@media (max-width: 380px) {
  .buttons-container .kids-zone.mobile,
  .btn-wrapper span,
  .btn-wrapper .arrow-icon {
    font-size: 1.1rem;
  }

  .btn-wrapper {
    padding: 20px 45px;
  }
}
