.doted-separator {
  width: 90vw;
  border-bottom: 3px dotted #f8c13b;
  max-width: 300px;
  margin: 20px auto;
}
@media (max-width: 300px) {
  .doted-separator {
    width: 80vw;
    max-width: 220px;
  }
}
