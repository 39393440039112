.todays-suggestion {
  width: 100%;
  /* max-width: 600px; */
  /* padding: 0 20px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.todays-suggestion .title {
  display: flex;
  align-items: center;
  width: 80vw;
  max-width: 400px;
}

.todays-suggestion .title span {
  font-weight: 500;
  font-size: 1.2rem;
}

.todays-suggestion .title span img {
  width: 20px;
  height: 20px;
}
.todays-suggestion.desktop .title {
  width: 100%;
  display: flex;
  justify-content: center;

  align-items: center;
  margin-top: 20px;
}
.todays-suggestion.desktop .title span img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
@media (min-width: 801px) and (max-width: 1300px) {
  .todays-suggestion.desktop .title span img {
    width: 20px;
    height: 20px;
  }
  .todays-suggestion .title span {
    font-weight: 500;
    font-size: 0.6rem;
  }
}
@media (max-width: 380px) {
  .todays-suggestion .title span {
    font-size: 0.9rem;
  }
}
@media (max-width: 300px) {
  .todays-suggestion .title span {
    font-size: 0.7rem;
  }
}
