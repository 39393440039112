.desktop-footer {
  position: absolute;
  bottom: 0;
  background-color: #3c4964;
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}
.desktop-footer .credit {
  background-color: #3c4964;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  padding: 0 50px;
  cursor: pointer;
}
.desktop-footer .desktop-footer-main {
  background-color: #3c4964;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex-grow: 1;
  padding: 0 50px;
}
.desktop-footer .desktop-footer-main .kids-zone,
.desktop-footer .desktop-footer-main .videos,
.desktop-footer .desktop-footer-main .clean,
.desktop-footer .desktop-footer-main .blog,
.desktop-footer .desktop-footer-main .privacy,
.desktop-footer .desktop-footer-main .contact-us,
.desktop-footer .desktop-footer-main .about-us,
.desktop-footer .desktop-footer-main .help {
  display: flex;
  background-color: #3c4964;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.desktop-footer .desktop-footer-main .clean img,
.desktop-footer .desktop-footer-main .blog img {
  background-color: #3c4964;
  width: 20px;
  height: auto;
}
.desktop-footer .desktop-footer-main .videos img {
  background-color: #3c4964;
  width: 25px;
  height: auto;
}
.desktop-footer .desktop-footer-main .kids-zone p,
.desktop-footer .desktop-footer-main .videos p,
.desktop-footer .desktop-footer-main .clean p,
.desktop-footer .desktop-footer-main .blog p,
.desktop-footer .desktop-footer-credit p,
.desktop-footer .desktop-footer-main .privacy p,
.desktop-footer .desktop-footer-main .contact-us p,
.desktop-footer .desktop-footer-main .about-us p,
.desktop-footer .desktop-footer-main .help p {
  background-color: #3c4964;
  color: #fff;
  font-size: 0.7rem;
  margin-bottom: 0;
}
.desktop-footer .desktop-footer-credit {
  padding-right: 25px;
  cursor: pointer;
}
.desktop-footer .desktop-footer-main .footer-icon {
  font-size: 20px;
}
@media (max-width: 800px) {
  .desktop-footer {
    display: none;
  }

  /* .desktop-footer .desktop-footer-main .videos p,
  .desktop-footer .desktop-footer-main .clean p,
  .desktop-footer .desktop-footer-main .blog p,
  .desktop-footer .desktop-footer-credit p {
    font-size: 0.5rem;
  }

  .desktop-footer .desktop-footer-main .videos img,
  .desktop-footer .desktop-footer-main .clean img,
  .desktop-footer .desktop-footer-main .blog img {
    width: 15px;
    height: auto;
  } */
}
