.favorites-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  width: 100%;
  min-height: 80vh;
}
.favorites-wrapper .title,
.favorites-wrapper.desktop .title {
  font-weight: 500;
  font-size: 1.5rem;
  margin-top: 30px;
  width: 100%;
}
.favorites-wrapper .favorite-recipes {
  padding: 0 20px;
  margin: 20px 0 50px 0;
}
/* desktop */
.favorites-wrapper.desktop {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
  max-width: none;
  min-height: 80vh;
}

.favorites-wrapper.desktop .favorite-recipes.desktop {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: minmax(200px, auto);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  width: 100%;
  padding: 20px 5%;
  cursor: pointer;
}
