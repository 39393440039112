/* mobile */
.weekly-recipes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0 0 0;
  width: 100vw;
  padding: 0 20px;
  max-width: 600px;
}

.weekly-recipes-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.weekly-recipes-top .title-wrapper,
.weekly-recipes-top .template {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.weekly-recipes-top .title-wrapper .title-mobile {
  font-size: 1rem;
  font-weight: 500;
  color: #3c4964;
}

.weekly-recipes-top .template {
  cursor: pointer;
}
.weekly-recipes-top .title-wrapper img {
  width: 25px;
}
.weekly-recipes-top .template span {
  font-size: 1rem;
  font-weight: 500;
}

/* mobile container */
.weekly-recipes-calender {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
  width: 100%;
  gap: 10px;
}
/* desktop */

.weekly-recipes-container.desktop {
  width: 100%;
  max-width: 80vw;
}
.weekly-recipes-top .title-wrapper.desktop img,
.weekly-recipes-top .template.desktop img {
  width: 30px;
}
.weekly-recipes-top .title-wrapper.desktop .title-desktop,
.weekly-recipes-top .template.desktop span {
  font-size: 1.2rem;
  font-weight: 500;
  color: #3c4964;
}

/* desktop container */

.weekly-recipes-calender.desktop {
  display: flex;
  justify-content: space-between;
}

/* desktop calender head */

.weekly-recipes-calender.desktop .calender .calender-header.desktop {
  width: 9vw;
  max-width: 150px;
  height: 50px;
  gap: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
/* desktop calender body */

.weekly-recipes-calender.desktop .calender .calender-body {
  width: 9vw;
  max-width: 150px;
  height: 100px;
}
/* desktop ends here */

.weekly-recipes-calender .calender {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}
.weekly-recipes-calender .calender .calender-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  background-color: #a2d2e2;
  border-radius: 4px 4px 0px 0px;
  padding: 10px;
}

.weekly-recipes-calender .calender .calender-header .day,
.weekly-recipes-calender .calender .calender-header .date {
  font-weight: 400;
  color: #3c4964;
  font-size: 0.9rem;
}

.weekly-recipes-calender .calender .calender-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 0px 0px 4px 4px;
  background: #ededed;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 150px;
}
.weekly-recipes-calender .calender .calender-body.desktop {
  height: 120px;
}
/* style for selected recipe for weekly calender */
.calender .calender-body .selected-recipe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.calender .calender-body .selected-recipe .recipe-thumbnail {
  border-radius: 59px;
  border: 3px solid #3c4964;
  width: 20px;
  height: 20px;
  margin: 20px 0 0 0;
  /* background: lightgray 50% / cover no-repeat; */
}

/* .recipe-thumbnail.desktop */
.calender .calender-body .selected-recipe .recipe-thumbnail.desktop {
  width: 40px;
  height: 40px;
  margin: 0;
}
.remove-recipe-from-calendar {
  margin-top: 20px;
}
.calender .calender-body .selected-recipe .recipe-short-name {
  font-size: 0.7rem;
  margin: 5px;
  text-align: center;
  padding: 0 5px;
  height: 10px;
}

.calender-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.calender-bottom .save-template {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 55px;
  border: 1px solid #3aa7d5;
  color: #3aa7d5;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 0.9rem;
  padding: 7px 18px;
  z-index: 2;
}
.calender-bottom.desktop {
  padding: 10px 2% 10px 3%;
}
.calender-bottom.desktop .save-template {
  font-size: 0.9rem;
}
/* choose-week mobile*/
.choose-week {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 5px;
}
.choose-week .calender-left-icon,
.choose-week .calender-right-icon {
  font-size: 15px;
  margin-bottom: 2px;
}
.choose-week .last-week,
.choose-week .next-week {
  font-size: 1rem;
}
.choose-week .line {
  width: 1.5px;
  height: 13px;
  background-color: #000;
}
/* choose-week desktop */
.choose-week.desktop {
  justify-content: flex-end;
  gap: 10px;
}

@media (min-width: 1100px) {
}

@media (min-width: 450px) and (max-width: 799px) {
  /* .calender .calender-body .selected-recipe {
    height: auto;
  } */
  .calender .calender-body .selected-recipe .recipe-thumbnail {
    width: 50px;
    height: 50px;
  }
  .calender .calender-body .selected-recipe .recipe-short-name {
    font-size: 0.9rem;
    height: 20px;
  }
}
@media (min-width: 371px) and (max-width: 449px) {
  .calender-bottom .save-template {
    font-size: 0.7rem;
    padding: 7px 15px;
  }
  .choose-week .last-week,
  .choose-week .next-week {
    font-size: 0.8rem;
  }

  .choose-week .calender-left-icon,
  .choose-week .calender-right-icon {
    font-size: 12px;
  }
  .calender .calender-body .selected-recipe .recipe-thumbnail {
    width: 50px;
    height: 50px;
  }
  .calender .calender-body .selected-recipe .recipe-short-name {
    font-size: 0.8rem;
  }
}

@media (max-width: 370px) {
  .calender .calender-body .selected-recipe .recipe-thumbnail {
    margin: 0;
  }
  .calender .calender-body .selected-recipe {
    height: auto;
  }
  .choose-week .line {
    width: 1px;
    height: 8px;
  }
  .choose-week .last-week,
  .choose-week .next-week {
    font-size: 0.6rem;
  }
  .choose-week .calender-left-icon,
  .choose-week .calender-right-icon {
    font-size: 9px;
  }

  .calender-bottom .save-template {
    font-size: 0.6rem;
    padding: 5px 13px;
  }
  .weekly-recipes-calender .calender .calender-header .day,
  .weekly-recipes-calender .calender .calender-header .date {
    font-size: 0.7rem;
  }
  .weekly-recipes-top .template span,
  .weekly-recipes-top .title-wrapper .title-mobile {
    font-size: 0.8rem;
    font-weight: 400;
  }
  .weekly-recipes-top {
    padding: 0 10px;
  }

  .weekly-recipes-top .title-wrapper img,
  .weekly-recipes-top .template img {
    width: 15px;
  }
  .weekly-recipes-calender .calender .calender-body {
    height: 100px;
  }

  .calender .calender-body .selected-recipe .recipe-thumbnail {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 350px) {
  .calender .calender-body .selected-recipe .recipe-thumbnail {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 320px) {
  .weekly-recipes-calender {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
  }
  .weekly-recipes-top .template span,
  .weekly-recipes-top .title-wrapper .title-mobile {
    font-size: 0.7rem;
  }
  .weekly-recipes-container {
    width: 80vw;
    max-width: 300px;
  }
  .remove-recipe-from-calendar {
    margin: 0;
  }
}
