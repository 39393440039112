.recipe-desktop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 270px;
  position: relative;
  box-shadow: -1px 2px 9px 2px rgba(0, 0, 0, 0.36);
  -webkit-box-shadow: -1px 2px 9px 2px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: -1px 2px 9px 2px rgba(0, 0, 0, 0.36);
  /* justify-content: center; */
}
.recipe-desktop-top {
  position: relative;
  z-index: 10;
}
.recipe-desktop-top .recipe-desktop-main-img {
  width: 100%;
  height: 110px;
}

.recipe-desktop-top .recipe-desktop-vegan,
.recipe-desktop-top .recipe-desktop-allergy {
  position: absolute;
  /* top: -2px; */
  z-index: 1;
  right: 20px;
  background-color: transparent;
  width: 40px;
  height: auto;
}
.recipe-desktop-top .recipe-desktop-allergy {
  top: 0;
}
.recipe-desktop-top .recipe-desktop-allergy {
  right: 75px;
  width: 40px;
}
.recipe-desktop-top .recipe-desktop-allergy.no-life-style-0 {
  right: 20px;
}
.recipe-desktop-top .recipe-desktop-allergy.no-life-style-1 {
  right: 70px;
}
.recipe-desktop-top .recipe-desktop-allergy-2 {
  right: 130px;
  width: 40px;
}
.recipe-desktop-top .recipe-desktop-allergy-2.no-life-style {
  right: 80px;
}
.recipe-desktop-top .recipe-desktop-heart {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 30px;
  height: auto;
}
.recipe-desktop-bottom {
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: relative;
  font-size: 0.9rem;
  border: none;
}
.recipe-desktop-bottom .recipe-desktop-title {
  font-size: 1rem;
  font-weight: 500;
  min-height: 50px;
}
.recipe-desktop-bottom .personal-recipe {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3aa7d5;
  gap: 10px;
  min-height: 50px;
}
.recipe-desktop-bottom .recipe-desktop-rating {
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  gap: 10px;
  /* padding-right: 10px; */
}

.recipe-desktop-bottom .recipe-desktop-rating .star-ratings {
  margin-bottom: 5px;
}
.recipe-desktop-bottom .recipe-desktop-rating .star-ratings svg path {
  fill: #3aa7d5 !important; /* Set the starFullColor with !important */
}
.recipe-desktop-bottom .recipe-desktop-cooking-time {
  display: flex;
  gap: 10px;
}
