.notification-popup-wrapper {
  opacity: 0.95;
  background: #3c4964;
  width: 100%;
  min-height: 100vh;
  z-index: 5000;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}
.notification-popup-wrapper .notification-popup-main {
  background-color: #f5f5f5;
  padding: 20px;
  width: 80vw;
  max-width: 350px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.notification-popup-main .notification-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.notification-popup-main .notification-popup-header img {
  width: 50px;
  height: 50px;
}
.notification-popup-main .notification-popup-body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.notification-popup-main .notification-popup-body .notification {
  margin-top: 20px;
  font-size: 1.3rem;
  padding-right: 10px;
  color: #206684;
}
.notification-popup-main .notification-popup-body .read-notification {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-popup-main .notification-popup-body .read-notification button {
  border-radius: 50px;
  padding: 5px 20%;
  border: #206684 1px solid;
  color: #206684;
}
