.recipe {
  display: flex;
  justify-content: center;
  max-width: 600px;
  width: 100vw;
  padding: 0 20px;
  margin: 10px 0;
  cursor: pointer;
}

.recipe .recipe-img {
  width: 35%;
  border-radius: 10px 0 0 10px;
  position: relative;
  overflow: hidden;
}

.recipe .recipe-img .heart {
  position: absolute;
  top: 7px;
  left: 7px;
  z-index: 1;
}
.recipe .recipe-img .heart img {
  width: 20px;
  height: 20px;
}
.recipe .recipe-details {
  width: 65%;
  border-radius: 0 10px 10px 0;
  display: flex;
  flex-direction: column;
  height: 200px;
  /* justify-content: center; */
}
.recipe .recipe-details .top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  padding-inline-end: 20px;
  position: relative;
}
.recipe .recipe-details .top span {
  padding: 0;
  margin: 0;
}
.recipe .recipe-details .top .recipe-allergy,
.recipe .recipe-details .top .recipe-vegan {
  width: 30px;
  position: relative;
  right: 25px;
  height: auto;
}
.recipe .recipe-details .name p {
  /* text-align: left; */
  font-weight: 500;
  font-size: 1.1rem;
  /* padding: 4px 10px 0 20px; */
  color: #3c4964;
  margin: 5px 0 10px 0;
}
.recipe .recipe-details .name .personal-recipe-name {
  text-align: left;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 4px 10px 0 20px;
  color: #3c4964;
  margin-top: 20px;
}

.recipe .recipe-details .personal-recipe-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 50px 0 0 0;
  color: #3aa7d5;
}
.recipe .recipe-details .personal-recipe-mobile span {
  font-size: 1rem;
}
.recipe .recipe-details .rating {
  display: flex;
  align-items: center;
  color: #3c4964;
  /* padding: 0 0 0 20px; */
  margin-bottom: 10px;
  /* margin: 5px 0 0 0; */
}
.recipe .recipe-details .rating span {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 5px;
}
.recipe .recipe-details .rating .star-ratings svg path {
  fill: #f8c13b !important; /* Set the starFullColor with !important */
}
.recipe .recipe-details .cooking-time,
.recipe .recipe-details .prep-time {
  /* padding: 0 20px;   */
  color: #3c4964;
  display: flex;
  align-items: center;
  /* gap: 10px; */
  font-size: 0.9rem;
}

.recipe .recipe-details .cooking-time .cooking-time-title,
.recipe .recipe-details .prep-time .prep-time-title {
  width: 35%;
}

.recipe .recipe-details .cooking-time .cooking-time-time,
.recipe .recipe-details .prep-time .prep-time-min {
  width: 20%;
}
.todays-suggestion .recipe {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  padding: 0 20px;
  margin: 10px 0;
  cursor: pointer;
}
.todays-suggestion .recipe .recipe-img {
  width: 35%;
  height: 200px;
  position: relative;
}
/* .todays-suggestion .recipe .recipe-img img {
} */
.todays-suggestion .recipe .recipe-details {
  justify-content: initial;
  width: 65%;
  height: 200px;
}
.todays-suggestion .recipe .recipe-details .name p {
  font-size: 0.9rem;
  padding: 4px 10px 0 10px;
  margin-bottom: 0;
}
.todays-suggestion .recipe .recipe-details .rating {
  padding: 10px 0 0 10px;
}

.todays-suggestion .recipe .recipe-details .rating span {
  font-size: 0.9rem;
}

.todays-suggestion .recipe .recipe-details .cooking-time {
  padding: 10px;
  font-size: 0.9rem;
}
.todays-suggestion .recipe .recipe-details .cooking-time,
.todays-suggestion .recipe .recipe-details .prep-time {
  padding: 0 10px; /* Adjust padding as needed */
  color: #3c4964;
  display: flex;
  align-items: center;
  /* gap: 10px; */
  font-size: 0.9rem;
}

/* .todays-suggestion .recipe .recipe-details .cooking-time .cooking-time-title,
.todays-suggestion .recipe .recipe-details .prep-time .prep-time-title {
  width: 30%;
 } */

/* .todays-suggestion .recipe .recipe-details .cooking-time .cooking-time-time,
.todays-suggestion .recipe .recipe-details .prep-time .prep-time-min {
  width: 40%;
} */

@media (min-width: 951px) and (max-width: 1200px) {
  /* break point for todays suggestion desktop  */
  /* .todays-suggestion .recipe .recipe-details .top .recipe-allergy,
  .recipe .recipe-details .top .recipe-vegan {
    width: 20px;
    position: relative;
    right: 15px;
    height: auto;
  } */
  .todays-suggestion .recipe .recipe-details .name p {
    font-size: 0.7rem;
    padding: 4px 10px 0 10px;
  }
  .recipe .recipe-details .top {
    padding-inline-end: 0;
  }
  .todays-suggestion .recipe .recipe-details .rating span {
    font-size: 0.7rem;
    margin: 5px 5px 0 0;
  }
  .todays-suggestion .recipe .recipe-details .cooking-time,
  .todays-suggestion .recipe .recipe-details .prep-time {
    font-size: 0.6rem;
  }
  .todays-suggestion .recipe .recipe-details .cooking-time .cooking-time-title,
  .todays-suggestion .recipe .recipe-details .prep-time .prep-time-title {
    width: 40%;
  }
}
@media (min-width: 801px) and (max-width: 950px) {
  /* break point for todays suggestion desktop  */
  .todays-suggestion .recipe .recipe-details .top .recipe-allergy,
  .recipe .recipe-details .top .recipe-vegan {
    width: 20px;
    position: relative;
    right: 15px;
    height: auto;
  }
  .recipe .recipe-details .top {
    padding-inline-end: 0;
  }

  .todays-suggestion .recipe .recipe-details .name p {
    font-size: 0.7rem;
    padding: 4px 10px 0 10px;
    margin-bottom: 0;
  }
  .todays-suggestion .recipe .recipe-details .rating {
    padding-left: 10px;
  }
  .todays-suggestion .recipe .recipe-details .rating span {
    font-size: 0.5rem;
    margin: 5px 5px 0 0;
  }
  .todays-suggestion .recipe .recipe-details .cooking-time,
  .todays-suggestion .recipe .recipe-details .prep-time {
    gap: 5px;
    font-size: 0.5rem;
  }
}

@media (max-width: 800px) {
  /* todays suggestion for mobile */
  /* for todays suggestion mobile view*/
  .todays-suggestion .recipe {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    padding: 0 10px;
    margin: 10px 0;
    cursor: pointer;
  }
}

@media (max-width: 520px) {
  .todays-suggestion .recipe .recipe-details .prep-time-title,
  .todays-suggestion .recipe .recipe-details .cooking-time-title,
  .recipe .recipe-details .cooking-time .cooking-time-title,
  .recipe .recipe-details .prep-time .prep-time-title {
    width: 50%;
  }
  .todays-suggestion .recipe .recipe-details .cooking-time-time,
  .todays-suggestion .recipe .recipe-details .prep-time-min,
  .recipe .recipe-details .cooking-time .cooking-time-time,
  .recipe .recipe-details .prep-time .prep-time-min {
    width: 40%;
  }
}
@media (max-width: 380px) {
  .recipe .recipe-details .rating {
    margin-bottom: 10px;
  }
  .todays-suggestion .recipe .recipe-details .prep-time-title,
  .todays-suggestion .recipe .recipe-details .cooking-time-title,
  .recipe .recipe-details .cooking-time .cooking-time-title,
  .recipe .recipe-details .prep-time .prep-time-title {
    margin-bottom: 10px;
  }
  .todays-suggestion .recipe .recipe-details .cooking-time-time,
  .todays-suggestion .recipe .recipe-details .prep-time-min,
  .recipe .recipe-details .cooking-time .cooking-time-time,
  .recipe .recipe-details .prep-time .prep-time-min {
    margin-bottom: 10px;
  }
  .recipe .recipe-details .top .recipe-allergy,
  .recipe .recipe-details .top .recipe-vegan {
    width: 20px;
    right: 20px;
  }
  .recipe .recipe-details .top {
    padding-inline-end: 0;
  }
  .todays-suggestion .recipe .recipe-details .name p {
    font-size: 0.9rem;
  }
  .recipe .recipe-details .personal-recipe-mobile span {
    font-size: 0.7rem;
  }

  .recipe .recipe-details .name p {
    font-size: 0.9rem;
    padding: 4px 10px 0 10px;
  }

  .recipe .recipe-details .rating,
  .todays-suggestion .recipe .recipe-details .rating {
    font-size: 0.7rem;
    padding: 0 0 0 10px;
  }

  .recipe .recipe-details .rating span,
  .todays-suggestion .recipe .recipe-details .rating span {
    font-size: 0.7rem;
    /* margin: 5px 5px 0 0; */
  }
  .todays-suggestion .recipe .recipe-details .cooking-time,
  .todays-suggestion .recipe .recipe-details .prep-time {
    font-size: 0.7rem;
    /* padding: 0 10px 0 10px; */
  }
  .recipe .recipe-details .cooking-time,
  .recipe .recipe-details .prep-time {
    font-size: 0.7rem;
    /* padding: 0 10px 0 10px; */
  }
  .recipe .recipe-details .prep-time {
    padding-bottom: 10px;
  }
}
@media (max-width: 300px) {
  .recipe .recipe-details .name p {
    font-size: 0.8rem;
  }
  .recipe .recipe-details .cooking-time,
  .recipe .recipe-details .prep-time,
  .recipe .recipe-details .rating {
    font-size: 0.6rem;
  }
  .recipe .recipe-details .cooking-time,
  .recipe .recipe-details .prep-time {
  }
  .recipe .recipe-details .rating {
  }
}
