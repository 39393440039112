.search-results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  min-height: 80vh;
  margin-bottom: 100px;
}

.search-results-container .search-results-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.search-results-container .search-results-wrapper .results-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 320px;
}

.search-results-container .search-results-wrapper h2 {
  margin: 30px;
}

/*  desktop grid  */
.search-results-container .search-results-wrapper .results-wrapper-desktop {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: minmax(200px, auto);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  width: 100%;
  padding: 20px 5%;
  cursor: pointer;
  margin-bottom: 100px;
  /* color: #3C4964; */
}
.search-results-container .buttons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
.search-results-container button {
  display: flex;
  padding: 13px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 55px;
  width: clamp(160px, 15vw, 200px);
  background: #f68222;
  border: none;
  color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
}
@media (max-width: 450px) {
  .search-results-container .buttons-wrapper {
    flex-direction: column;
  }
  .search-results-container button {
    width: 90vw;
  }
}
