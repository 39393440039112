.testimonials-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  position: relative;
  width: 100vw;
  max-width: 600px;
  margin-top: 50px;
}
.testimonials-container.desktop {
  width: 100%;
  margin-top: 50px;
}
.testimonials-container .left-arrow img,
.testimonials-container .right-arrow img {
  width: 20px;
  height: auto;
  cursor: pointer;
  margin-bottom: 40px;
}
.testimonials-container .center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.testimonials-container .center .main {
  background-color: #efefef;
  width: 80vw;
  max-width: 560px;
  position: relative;
  background: #efefef;
  color: #3c4964;
  font-family: Arial;
  font-size: 12px;
  height: 160px;
  border-radius: 12px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
}

.testimonials-container.desktop .center .main.desktop {
  width: 100%;
}
.testimonials-container .quote-image {
  position: absolute;
  top: -15px;
  left: -25px;
  z-index: 50;
}

.testimonials-container .testimonials-bubble {
  font-size: 1rem;
  font-weight: 500;
}
.testimonials-bubble.desktop {
  padding: 20px 15px 10px 15px;
  text-align: left;
  font-size: 0.7rem;
  overflow-y: scroll;
  height: 100px;
}
/* style .testimonials-bubble.desktop  scroll bar */
.testimonials-bubble.desktop::-webkit-scrollbar {
  width: 5px;
}
.testimonials-bubble.desktop::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 5px;
}
.testimonials-bubble.desktop::-webkit-scrollbar-track {
  background-color: #efefef;
}
.testimonials-container .testimonials-bubble:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 0 0 20px 20px;
  border-color: transparent transparent #efefef transparent;
  top: 93%;
  left: -16px;
  margin-top: -10px;
}
.testimonials-container .testimonials-avatar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
}
.testimonials-container .testimonials-avatar .testimonial-img-circle {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 4px solid #71bc00;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonials-container .testimonials-avatar .testimonial-img-circle img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.testimonials-container .testimonials-avatar .testimonial-img-circle.desktop {
  width: 34px;
  height: 34px;
}

.testimonials-container .testimonials-bubble .testimonial-admin {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}

@media (max-width: 1350px) {
  /* .testimonials-bubble.desktop {
    font-size: 0.6rem;
  } */
}
/* @media (min-width: 1000px) {
  .testimonials-container.desktop .center .main.desktop {
    width: 300px;
  }
} */
@media (max-width: 650px) {
  .testimonials-container {
    width: 90vw;
  }

  .testimonials-container .center .main {
    width: 80vw;
    /* max-width: 560px; */

    padding: 0 20px;
  }
}

@media (max-width: 450px) {
  /* .testimonials-container {
    width: 90vw;
  } */

  .testimonials-container .center .main {
    width: 70vw;
  }
}
@media (max-width: 300px) {
  .testimonials-container .testimonials-avatar span {
    font-size: 0.8rem;
  }
  .testimonials-container .testimonials-avatar .testimonial-img-circle {
    width: 34px;
    height: 34px;
  }
  .testimonials-container .testimonials-avatar img {
    width: 30px;
    height: 30px;
  }
}
