.recipe-review-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.recipe-review-container .box-it-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  height: 80px;
  background-color: rgba(60, 73, 100, 0.9);
  position: fixed;
  bottom: 0;
  cursor: pointer;
}
.recipe-review-container .box-it-wrapper button {
  display: flex;
  padding: 14px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 55px;
  background: #f68222;
  border: none;
  color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 35%;
  max-width: 260px;
  font-size: 1rem;
  font-weight: 500;
}
.recipe-review-container .recipe-review-main {
  display: flex;
  width: 100%;
  max-width: 600px;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}
.recipe-review-main .review-image {
  /* width: 80vw; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.recipe-review-main .review-image img {
  width: 100%;
  max-width: 200px;
  /* margin: 0 auto; */
}
.recipe-review-main .review-title,
.recipe-review-main .review-cooking-prep {
  font-weight: 500;
  color: #3c4964;
  text-align: center;
  width: 100%;
  max-width: 600px;
}
.recipe-review-main .review-title {
  font-size: 2rem;
  margin-top: 20px;
}
.recipe-review-main .review-cooking-prep {
  font-size: 1rem;
  margin: 10px 0;
}

.recipe-review-main .review-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 600px;
}
.recipe-review-main .review-buttons .btn-review {
  background-color: #a2d2e2;
  padding: 12px 20px;
  border: none;
  border-radius: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 1rem;
  margin-bottom: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.recipe-review-main .review-buttons .btn-review.active {
  background-color: #3c4964;
}
.recipe-review-main .review-buttons .btn-review .icon-btn {
  font-size: 1.2rem;
  color: #fff;
}
.recipe-review-main .recipe-description {
  font-weight: 500;
  color: #3c4964;
  font-size: 1rem;
  /* margin: 0 0 20px 0; */
  text-align: center;
  width: 95%;
  max-width: 330px;
  margin-bottom: 20px;
}

.recipe-review-main .recipe-health-rate {
  width: 95%;
  max-width: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin: 0 0 20px 0;
}
.recipe-review-main .health-data {
  width: 100%;
  max-width: 600px;
  margin: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.recipe-review-main .health-data .calories,
.recipe-review-main .health-data .allergens {
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  /* margin-inline-start: 20%; */
}
/* .recipe-review-main .health-data div span {
  font-size: 1.2rem;
} */

.recipe-review-main .nutrition-info {
  width: 95%;
  max-width: 330px;
}
.recipe-review-main .nutrition-info {
  width: 95%;
  max-width: 330px;
}
.recipe-review-main .nutrition-info .recipe-nutritional-value {
  color: #3aa7d5;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  width: 100%;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.recipe-review-main .current-recipe-rate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 30px;
}
.recipe-review-main .current-recipe-rate .average-rating {
  font-size: 0.8rem;
  color: #3c4964;
}
.recipe-review-main .current-recipe-rate .reset-rating,
.recipe-review-main-desktop .current-recipe-rate-desktop .reset-rating,
.recipe-review-main .current-recipe-rate .rate-recipe,
.recipe-review-main-desktop .current-recipe-rate-desktop .rate-recipe {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  margin: 10px 0;
  background-color: #f68222;
  padding: 8px 25px;
  color: #fff;
  border-radius: 44px;
  cursor: pointer;
  max-width: 150px;
}

/* rate buttons */
.recipe-review-main .current-recipe-rate .rate-recipe,
.recipe-review-main .current-recipe-rate .reset-rating {
  background-color: #f68222;
}

.recipe-review-main-desktop .current-recipe-rate-desktop .reset-rating {
  width: 40%;
  max-width: 150px;
}
.recipe-review-main .recipe-ingredients,
.recipe-review-main .recipe-preparation,
.recipe-review-main .recipe-main-dish {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  gap: 5px;
}
.recipe-review-main .recipe-preparation {
  margin-bottom: 30px;
}
.recipe-review-main .recipe-ingredients .title,
.recipe-review-main .recipe-preparation .title,
.recipe-review-main .recipe-main-dish .title {
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: 3px dotted #71bc00;
  color: #3c4964;
}
.recipe-review-main .recipe-main-dish .title {
  border-bottom: 3px dotted #3c4964;
  gap: 15px;
}
.recipe-review-main .recipe-preparation .title {
  border-bottom: 3px dotted #f68222;
  /* gap: 10px; */
}
.recipe-review-main .recipe-ingredients .title img,
.recipe-review-main .recipe-preparation .title img {
  width: 40px;
  height: 40px;
  margin-bottom: 7px;
}

.recipe-review-main .recipe-main-dish .title img {
  width: 30px;
  height: 30px;
  margin-bottom: 7px;
}
.recipe-review-main .recipe-ingredients .title h2 {
  font-size: 1.2rem;
}

.recipe-review-main .recipe-preparation .title h2,
.recipe-review-main .recipe-main-dish .title h2 {
  font-size: 1.2rem;
  padding-top: 6px;
}

.recipe-review-main .recipe-ingredients .recipe-ingredients-list {
}

.recipe-review-main .recipe-ingredients .recipe-ingredients-list li,
.recipe-review-main .recipe-preparation .preparation-steps li {
  margin: 10px 0;
  font-size: 1rem;
}
.recipe-review-main .recipe-preparation .preparation-steps li {
  list-style: none;
}

.recipe-review-main .recipe-notes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  width: 100%;
  max-width: 600px;
  color: #000000;
}
.recipe-review-main .recipe-notes .title {
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: 3px dotted #a2d2e2;
  color: #3c4964;
}
.recipe-review-main .recipe-notes .title h2 {
  font-size: 1.2rem;
}
.recipe-review-main .recipe-notes p {
  font-size: 1rem;
  padding-inline-start: 15px;
}

.recipe-review-main .recipe-buttons-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 600px;
  margin-bottom: 150px;
  cursor: pointer;
}
.recipe-review-main .recipe-buttons-mobile .delete-recipe,
.recipe-review-main .recipe-buttons-mobile .edit-recipe {
  background-color: #d83a30;
  color: #fff;
  padding: 17px 47px;
  border: none;
  border-radius: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 1rem;
  font-weight: 500;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 40%;
}
.recipe-review-main .recipe-buttons-mobile .edit-recipe {
  background-color: #a2d2e2;
}
.recipe-review-main .recipe-buttons-mobile .close-recipe {
  background-color: #f68222;
}
.recipe-review-main .recipe-buttons-mobile .close-recipe span,
.recipe-review-main .recipe-buttons-mobile .delete-recipe span,
.recipe-review-main .recipe-buttons-mobile .edit-recipe span {
  font-size: 1rem;
}
/* Desktop */

.recipe-review-container-desktop {
  margin-top: 30px;
  width: 85%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.recipe-review-container-desktop .recipe-review-desktop-title {
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 500;
  color: #3c4964;
}

.recipe-review-container-desktop .recipe-review-desktop-cooking-prep {
  margin-bottom: 20px;
}

.recipe-review-container-desktop .recipe-review-main-desktop {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.recipe-review-main-desktop .right {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.recipe-review-main-desktop .right img {
  width: 100%;
  max-width: 500px;
}
.recipe-review-main-desktop .right .recipe-buttons-desktop {
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 500px;
}
.recipe-review-main-desktop .right .recipe-buttons-desktop .close-recipe,
.recipe-review-main-desktop .right .recipe-buttons-desktop .delete-recipe,
.recipe-review-main-desktop .right .recipe-buttons-desktop .edit-recipe {
  background-color: #d83a30;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 1rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 30%;
  font-weight: 500;
}
.recipe-review-main-desktop .right .recipe-buttons-desktop .edit-recipe {
  background-color: #a2d2e2;
}
.recipe-review-main-desktop .right .recipe-buttons-desktop .close-recipe {
  background-color: #f68222;
}

.recipe-review-main-desktop .right .recipe-buttons-desktop .close-recipe span,
.recipe-review-main-desktop .right .recipe-buttons-desktop .delete-recipe span,
.recipe-review-main-desktop .right .recipe-buttons-desktop .edit-recipe span {
  font-size: 1rem;
}

.recipe-review-main-desktop .left {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.recipe-review-main-desktop .left .buttons-wrapper-desktop {
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  align-items: center;
  padding-inline-end: 80px;
  margin-bottom: 20px;
}
.left .buttons-wrapper-desktop .review-buttons-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.left .buttons-wrapper-desktop .review-buttons-desktop .btn-review {
  background-color: #a2d2e2;
  color: #fff;
  padding: 10px 12px;
  border: none;
  border-radius: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 1rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.left .buttons-wrapper-desktop .review-buttons-desktop .btn-review.active {
  background-color: #3c4964;
}
.left .buttons-wrapper-desktop .review-buttons-desktop .btn-review .icon-btn {
  font-size: 1rem;
  color: #fff;
}
.left .buttons-wrapper-desktop .box-it-desktop {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 55px;
  background: #f68222;
  border: none;
  color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  font-size: 1rem;
}
.left .recipe-description-desktop {
  font-weight: 500;
  color: #3c4964;
  font-size: 1.2rem;
  margin: 10px 0 20px 0;
  width: 95%;
  max-width: 330px;
}

.left .recipe-health-rate-desktop {
  width: 95%;
  max-width: 500px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}

/* both mobile and desktop */
.score-in-words {
  display: flex;
  flex-direction: column;
  /* align-items: baseline; */
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
}

.score-in-words .nutrition_rate {
  font-size: 1rem;
}

.score-in-words .health-score-in-words {
  padding: 0 5px;
  font-size: 1.2rem;
  letter-spacing: 3px;
  text-align: center;
}
.left .recipe-health-rate-desktop .health-data {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.health-data div {
  font-size: 0.7rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: rgb(175, 172, 172);
  justify-content: row-reverse;
}
.health-data div span {
  font-size: 1rem;
  font-weight: 500;
  color: "rgb(175, 172, 172)";
}
.left .recipe-nutritional-value-desktop {
  color: #3aa7d5;
  text-decoration: underline;
  cursor: pointer;
}
.left .recipe-nutritional-value-desktop:hover {
  text-decoration: none;
}

.left .current-recipe-rate-desktop {
  padding-inline-start: 8%;
}

.left .current-recipe-rate-desktop .average-rating {
  font-size: 0.8rem;
  margin: 5px 0;
  color: #3c4964;
  padding-inline-start: 15px;
}

.left .recipe-ingredients-desktop,
.left .recipe-preparation-desktop,
.left .recipe-main-dish-desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85%;
  gap: 5px;
}

.left .recipe-ingredients-desktop .title,
.left .recipe-preparation-desktop .title,
.left .recipe-main-dish-desktop .title {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 3px dotted #71bc00;
  color: #3c4964;
}

.left .recipe-preparation-desktop .title {
  border-bottom: 3px dotted #f68222;
}
.left .recipe-main-dish-desktop .title {
  border-bottom: 3px dotted #3c4964;
  gap: 15px;
}

.left .recipe-ingredients-desktop .title h2,
.left .recipe-preparation-desktop .title h2,
.left .recipe-main-dish-desktop .title h2 {
  font-size: 1.2rem;
  padding-top: 6px;
}
.left .recipe-ingredients-desktop .title img,
.left .recipe-preparation-desktop .title img {
  width: 40px;
  height: 40px;
  margin-bottom: 7px;
}
.left .recipe-main-dish-desktop .title img {
  width: 30px;
  height: 30px;
  margin-bottom: 7px;
}

.left .recipe-preparation-desktop .preparation-steps {
  width: 70%;
  padding: 0;
}

.left .recipe-ingredients-desktop .recipe-ingredients-list li,
.left .recipe-preparation-desktop .preparation-steps li,
.recipe-main-dish-desktop .main-dish-desktop li {
  margin: 10px 0;
}
.left .recipe-preparation-desktop .preparation-steps li {
  list-style: none;
}
.left .recipe-preparation-desktop .preparation-steps li:last-child {
  margin-bottom: 30px;
}

.preparation-steps li .step-number {
  font-weight: 500;
  display: inline-block;
  width: 65px;
}
.left .recipe-notes-desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  width: 85%;
  margin-bottom: 100px;
  color: #000000;
}
.left .recipe-notes-desktop .title {
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: 3px dotted #a2d2e2;
  color: #3c4964;
}
.left .recipe-notes-desktop .title h2 {
  font-size: 1.2rem;
}
.left .recipe-notes-desktop p {
  font-size: 1rem;
  padding-inline-start: 15px;
}
.footer-wrapper {
  position: relative;
  width: 100%;
}
@media (min-width: 1300px) {
  .recipe-review-main-desktop .left .buttons-wrapper-desktop {
    gap: 100px;
  }
  .left .recipe-health-rate-desktop {
    max-width: 700px;
    gap: 50px;
  }
  .left .recipe-health-rate-desktop .health-data div span {
    font-size: 1rem;
  }
}
@media (min-width: 1161px) and (max-width: 1299px) {
  .left .recipe-health-rate-desktop {
    max-width: 700px;
    gap: 50px;
  }
  .left .recipe-health-rate-desktop .health-data div span {
    font-size: 1rem;
  }
  /* .score-in-words {
    font-size: 0.8rem;
  } */
}
/* break point for desktop */
@media (min-width: 801px) and (max-width: 1160px) {
  .left .recipe-health-rate-desktop {
    max-width: 700px;
    gap: 40px;
  }

  .recipe-review-main-desktop .right .recipe-buttons-desktop .close-recipe,
  .recipe-review-main-desktop .right .recipe-buttons-desktop .delete-recipe,
  .recipe-review-main-desktop .right .recipe-buttons-desktop .edit-recipe {
    padding: 8px 16px;
  }
  .recipe-review-main-desktop .right .recipe-buttons-desktop .close-recipe span,
  .recipe-review-main-desktop
    .right
    .recipe-buttons-desktop
    .delete-recipe
    span,
  .recipe-review-main-desktop .right .recipe-buttons-desktop .edit-recipe span {
    font-size: 0.8rem;
  }

  .recipe-review-main-desktop .left .buttons-wrapper-desktop {
    display: flex;
    gap: 20px;
    padding-inline-end: 20px;
  }
  .left .buttons-wrapper-desktop .review-buttons-desktop {
    gap: 5px;
    padding-inline-end: 15px;
  }
  .recipe-review-main-desktop .current-recipe-rate-desktop .reset-rating,
  .recipe-review-main-desktop .current-recipe-rate-desktop .rate-recipe,
  .left .buttons-wrapper-desktop .review-buttons-desktop .btn-review {
    font-size: 0.6rem;
    padding: 8px 12px;
  }
  .left .buttons-wrapper-desktop .review-buttons-desktop .btn-review .icon-btn {
    font-size: 0.8rem;
  }

  .left .buttons-wrapper-desktop .box-it-desktop {
    padding: 8px 12px;
    font-size: 0.6rem;
  }
  .left .current-recipe-rate-desktop .average-rating {
    font-size: 0.6rem;

    padding-inline-start: 15px;
  }
  /* .score-in-words {
    font-size: 0.8rem;
  } */
}

@media (max-width: 500px) {
  /* .recipe-review-main .health-data div span {
    font-size: 0.8rem;
  } */
  .recipe-review-main .nutrition-info .recipe-nutritional-value {
    font-size: 1rem;
  }

  .recipe-review-container .box-it-wrapper .btn-box-it {
    padding: 12px 40px;
  }

  .recipe-review-main .review-buttons .btn-review {
    gap: 5px;
  }
  .recipe-review-main .review-buttons .btn-review .icon-btn {
    font-size: 0.8rem;
  }
  .recipe-review-main .recipe-ingredients .title h2,
  .recipe-review-main .recipe-preparation .title h2 {
    font-size: 1rem;
  }
  .recipe-review-main .recipe-buttons-mobile .close-recipe span,
  .recipe-review-main .recipe-buttons-mobile .delete-recipe span,
  .recipe-review-main .recipe-buttons-mobile .edit-recipe span {
    font-size: 0.9rem;
  }
}

@media (max-width: 450px) {
  .recipe-review-main .review-title {
    font-size: 1.6rem;
  }
  .recipe-review-main .review-buttons .btn-review {
    /* font-size: 0.7rem; */
    padding: 10px 20px;
  }
}

@media (max-width: 350px) {
  .recipe-review-main .review-buttons {
    gap: 5px;
  }
  .recipe-review-main .review-buttons .btn-review {
    padding: 6px 10px;
  }

  .recipe-review-main .recipe-buttons-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .recipe-review-main .review-cooking-prep {
    font-size: 0.9rem;
  }
  .recipe-review-main .recipe-buttons-mobile .close-recipe,
  .recipe-review-main .recipe-buttons-mobile .delete-recipe,
  .recipe-review-main .recipe-buttons-mobile .edit-recipe {
    padding: 12px 20px;
    width: 80%;
  }
  .recipe-review-main .recipe-buttons-mobile .close-recipe span,
  .recipe-review-main .recipe-buttons-mobile .delete-recipe span,
  .recipe-review-main .recipe-buttons-mobile .edit-recipe span {
    font-size: 1rem;
  }

  .recipe-review-main .recipe-ingredients .title h2,
  .recipe-review-main .recipe-preparation .title h2 {
    font-size: 1rem;
  }

  .recipe-review-main .recipe-buttons-mobile .delete-recipe,
  .recipe-review-main .recipe-buttons-mobile .edit-recipe {
    font-size: 0.6rem;
  }
}
