.weekly-supply-container {
  width: 100vw;
  max-width: 600px;
  padding: 0 20px;
  /* margin-left: 20px; */
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-bottom: 15px;
}

.weekly-supply-container.desktop {
  width: 100%;
  max-width: none;
  margin-top: 20px;
}

.weekly-title {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  font-weight: 500;
  font-size: 1.2rem;
}
.weekly-disclaimer {
  font-size: 1.1rem;
  color: #e22525;
  margin: 0 0 20px 15px;
  padding: 0 10px;
  width: 100%;
  text-align: center;
}
.weekly {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 50px 0;
  margin: 10px 0;
  background-color: #e0eef2;
  border-radius: 6px 6px 0px 0px;
  --mask: conic-gradient(
      from -32.5deg at bottom,
      #0000,
      #000 1deg 64deg,
      #0000 65deg
    )
    50%/22px 100%;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  padding-bottom: 50px;
}

.weekly-desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 50vw;
  max-width: 570px; */
  padding: 20px 10px 30px 20px;
  background-color: #e0eef2;
  border-radius: 6px 6px 0px 0px;
  --mask: conic-gradient(
      from -32.5deg at bottom,
      #0000,
      #000 1deg 64deg,
      #0000 65deg
    )
    50%/22px 100%;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

.weekly-desktop .weekly-desktop-title {
  font-size: 1rem;
  color: #3c4964;
  padding: 10px 0 10px 0;
}
.weekly-desktop .weekly-desktop-disclaimer {
  font-size: 0.9rem;
  margin-bottom: 10px;
  color: #e22525;
  padding: 10px 0 0 5px;
}
.weekly .weekly-trigger {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.weekly .weekly-trigger span {
  color: #3c4964;
  font-size: 1.2rem;
  font-weight: 500;
}

.weekly .weekly-trigger span .arrow-icon {
  color: #3c4964;
  cursor: pointer;
}
.weekly .weekly-ingredients {
  width: 100%;
  padding-right: 20px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin: 0 auto 20px auto;
}

.weekly-desktop .weekly-ingredients {
  padding-right: 0;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px;
  height: 80px;
  overflow-y: scroll;
}
.weekly-desktop .weekly-ingredients.no-grid {
  display: flex;
  justify-content: center;
  align-items: center;
}
.weekly-desktop .weekly-ingredients .no-ingredients,
.no-ingredients-mobile {
  color: #3c4964;
  font-size: 12px;
  padding: 10px 0 0 5px;
  width: 100%;
}
.no-ingredients-mobile .choose-recipe {
  text-align: center;
  margin-left: -40px;
  width: 100%;
}
.weekly-desktop .weekly-ingredients::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

.weekly-desktop .weekly-ingredients::-webkit-scrollbar-thumb {
  background-color: #fff; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}

/* Styling the scrollbar track on hover */
.weekly-desktop .weekly-ingredients::-webkit-scrollbar-track:hover {
  background-color: #888; /* Color of the track on hover */
}

.weekly .weekly-ingredients .ingredient,
.weekly-desktop .weekly-ingredients .ingredient {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  gap: 5px;
  margin-bottom: 5px;
  padding-inline-start: 15%;
}
.weekly .weekly-ingredients .ingredient .supplies {
  font-size: 1rem;
}
.weekly-desktop .weekly-ingredients .ingredient {
  padding-inline-start: 0;
  margin-bottom: 5px;
}

.weekly .weekly-ingredients .ingredient input {
  width: 20px;
  height: 20px;
}

.weekly-desktop .weekly-ingredients .ingredient input {
  width: 15px;
  height: 15px;
}
.weekly .weekly-ingredients .ingredient span {
  font-size: 0.8rem;
}
.weekly-desktop .weekly-ingredients .ingredient span {
  font-size: 0.8rem;
}
.weekly .whatsApp-btn,
.weekly-desktop .whatsApp-btn {
  border-radius: 55px;
  border: 1px solid #71bc00;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 25px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 700;
  color: #71bc00;
}

.weekly-desktop .whatsApp-btn {
  position: relative;
  top: 5px;
  right: 10px;
  font-size: 0.6rem;
  width: 50%;
  padding: 10px 20px;
  max-width: 200px;
  align-self: flex-end;
  box-shadow: none;
  /* margin: 20px auto 10px auto; */
}

.weekly .whatsApp-btn span img {
  width: 25px;
  height: auto;
}
.weekly-desktop .whatsApp-btn span img {
  width: 15px;
}

@media (min-width: 1100px) {
  .weekly-desktop .weekly-ingredients .ingredient span {
    font-size: 0.8rem;
  }
}
@media (max-width: 430px) {
  .weekly-disclaimer {
    margin: 0 0 20px 0;
    font-size: 0.9rem;
    padding: 0 10px;
  }

  .no-ingredients-mobile .choose-recipe {
    margin-left: 0;
    padding: 0 10px;
  }
}
@media (max-width: 380px) {
  .weekly .weekly-ingredients {
    gap: 5px;
  }
  .weekly .weekly-ingredients .ingredient .supplies {
    font-size: 0.8rem;
  }
  .weekly-title {
    font-size: 0.9rem;
  }

  .weekly .weekly-ingredients .ingredient input {
    width: 13px;
    height: 13px;
  }
  .weekly .weekly-ingredients .ingredient span {
    font-size: 0.7rem;
  }
}
