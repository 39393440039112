.clean-box-container {
  opacity: 0.95;
  background: #3c4964;
  width: 100%;
  min-height: 100vh;
  z-index: 5000;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}
.clean-box-container .main-content {
  background-color: #fff;
  padding: 20px;
  width: 80vw;
  max-width: 350px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.clean-box-container .main-content .stars {
  margin: 10px 0;
}
.clean-box-container .main-content .stars img {
  width: 120px;
}
.clean-box-container .clean-box {
  height: 350px;
  overflow-y: scroll;
}

.clean-box-container .clean-box h2 {
  font-size: 1rem;
  text-align: center;
  font-weight: 600;
}
.clean-box-container .clean-box p {
  font-size: 0.8rem;
  margin: 0;
}

.clean-box-container .clean-box p span {
  font-size: 0.8rem;
  display: block;
  font-weight: 500;
}
.clean-box-container .clean-box ul {
  margin-top: 10px;
  padding-right: 15px;
}
.clean-box-container .clean-box ul li {
  margin-top: 10px;
  font-size: 0.8rem;
  padding: 0 10px;
}
.clean-box-container .more-information {
  margin: 20px 0;
  font-size: 0.8rem;
  text-decoration: underline;
  color: #3aa7d5;
  cursor: pointer;
}

.clean-box-container .more-information:hover {
  color: #f5872c;
}

.clean-box-container .button-wrapper {
  padding: 15px 40px;
  border-radius: 55px;
  min-width: 170px;
  width: 200px;
  max-width: 400px;
  background-color: #f68222;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  /* margin: 50px 0 100px 0; */
  gap: 20px;
  cursor: pointer;
}
.button-wrapper button {
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 1rem;
}
@media (max-width: 400px) {
  .clean-box-container .main-content {
    width: 90vw;
  }
  .clean-box-container .clean-box ul li {
    font-size: 0.7rem;
  }
  .clean-box-container .clean-box ul {
    margin-top: 10px;
  }
  .clean-box-container .main-content .stars img {
    width: 90px;
  }
}
