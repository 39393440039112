.nutritional-values-popup {
  background-color: #3c4964;
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 100;
}
.nutritional-values-main {
  background-color: #ffffff;
  width: 90vw;
  max-width: 700px;
  max-height: 550px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  margin: 50px 0;
  border-radius: 4px;
}

/* scroll bar css  */
/* Define scrollbar styles for Webkit browsers (e.g., Chrome, Safari) */
.nutritional-values-table::-webkit-scrollbar {
  width: 20px; /* Set the width of the scrollbar */
}

/* Define scrollbar thumb styles for Webkit browsers */
.nutritional-values-table::-webkit-scrollbar-thumb {
  background-color: blue; /* Set the color of the scrollbar thumb */
  border-radius: 1px; /* Set border radius */
}
s

/* Define scrollbar track styles for Webkit browsers */
.nutritional-values-table::-webkit-scrollbar-track {
  background-color: red; /* Set the color of the scrollbar track */
}

/* Define scrollbar thumb styles for Firefox */
.nutritional-values-table::-moz-scrollbar-thumb {
  background-color: blue; /* Set the color of the scrollbar thumb */
  border-radius: 10px; /* Set border radius */
}

/* Define scrollbar track styles for Firefox */
.nutritional-values-table::-moz-scrollbar-track {
  background-color: blue; /* Set the color of the scrollbar track */
}

.nutritional-values-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
}
.nutritional-values-header h2 {
  font-size: 1.7rem;
}
.nutritional-values-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.nutritional-values-footer {
  font-size: 0.9rem;
  padding: 0 20px;
  margin-bottom: 50px;
}
.nutritional-values-footer div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.nutritional-values-footer div button {
  margin-top: 30px;
  background-color: #d83a30;
  cursor: pointer;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 55px;
  font-size: 1rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 30%;
}
.nutritional-data {
  /* border: 2px solid #d31313; */
  /* border-radius: 25px; */
  width: 100%;
  padding: 20px;
}
.nutritional-data .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 10px;
}
.nutritional-data .bottom {
  font-size: 0.9rem;
}
.nutritional-data .top .left-side {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}
.nutritional-data .top .left-side .chart-wrapper {
  margin: 0 auto;
}

.nutritional-data .top .left-side .current-score {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
}
.nutritional-data .top .right-side {
  font-size: 1rem;
}
.nutritional-data .top .right-side div {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
}
.nutritional-data .top .right-side div .square {
  width: 15px;
  height: 15px;
  display: inline-block;
}
.nutritional-data .top .right-side div .green {
  background-color: #95bf3e;
}
.nutritional-data .top .right-side div .yellow {
  background-color: #f6dd38;
}

.nutritional-data .top .right-side div .red {
  background-color: #fdb72c;
}
.nutritional-values-bottom {
  margin-bottom: 30px;
  font-size: 1rem;
}
.nutritional-values-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

/* Style the table headers */
.nutritional-values-table th {
  background-color: #f2f2f2;
  border: 1px solid #dddddd;
  /* text-align: left; */
  padding: 8px;
}

/* Style the table rows */
.nutritional-values-table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

/* Set fixed column widths */

.nutritional-values-table th,
.nutritional-values-table td {
  font-size: 1rem;
}

.nutritional-values-table th:first-child,
.nutritional-values-table td:first-child {
  width: 50%;
}

.nutritional-values-table th:nth-child(2),
.nutritional-values-table td:nth-child(2) {
  width: 25%;
}

.nutritional-values-table th:nth-child(3),
.nutritional-values-table td:nth-child(3) {
  width: 25%;
}

@media (max-width: 600px) {
  /* .nutritional-data .top .left-side {
    flex-direction: column;
    align-items: flex-start;
  } */

  /* .nutritional-data .top {
    flex-direction: column;
  } */
  .nutritional-data .top .left-side .current-score,
  .nutritional-data .top .right-side,
  .nutritional-data .bottom,
  .nutritional-values-bottom {
    font-size: 0.8rem;
  }
}

@media (max-width: 460px) {
  .nutritional-data .top .left-side {
    flex-direction: column;
  }
  .nutritional-data .top {
    justify-content: space-evenly;
  }
  .nutritional-data .top .left-side .current-score,
  .nutritional-data .top .right-side {
    font-size: 0.8rem;
  }

  .nutritional-values-header {
    padding: 0 10px;
  }
  .nutritional-values-header h2 {
    font-size: 1rem;
  }
  .nutritional-values-table th,
  .nutritional-values-table td {
    font-size: 0.8rem;
  }

  .nutritional-values-table th:nth-child(2),
  .nutritional-values-table td:nth-child(2) {
    width: 50%;
  }

  .nutritional-values-table th:nth-child(3),
  .nutritional-values-table td:nth-child(3) {
    width: 50%;
  }
}
@media (max-width: 380px) {
  .nutritional-data .top {
    flex-direction: column;
  }
  .nutritional-data .top .left-side .current-score,
  .nutritional-data .top .right-side {
    font-size: 0.8rem;
  }
  .nutritional-values-bottom {
    margin-bottom: 50px;
  }
}
