.notification-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
  max-width: 600px;
  width: 100vw;
  padding: 0 20px;
}
.notification-container.desktop {
  width: 200px;
}
.notification-container .main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 10px 20px 0 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  width: 100%;
}

.notification-container .main .bubble {
  width: 20%;
}

.notification-container .main .bubble img {
  min-width: 30px;
  width: 30px;
  max-width: 40px;
  padding-bottom: 10px;
}

.notification-container .main .message {
  font-size: 0.9rem;
  width: 60%;
  color: #206684;
}
.notification-container .main .delete {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.notification-container .close {
  display: flex;
  align-self: flex-end;
  justify-content: center;
  height: 25px;
  gap: 10px;
  border-radius: 8px;
  background: #fff;
  position: absolute;
  bottom: -16px;
  right: 30px;
  padding: 3px 18px;
  border: #206684 0.5px solid;
  cursor: pointer;
}

.notification-container .close p,
.notification-container .close .close-icon {
  color: #206684;
  font-size: 0.9rem;
}
