.recipes-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
}

.recipes-container .recipes-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  max-width: 600px;
  padding: 0 30px;
  font-weight: 500;
  font-size: 1.2rem;
}
.recipes-container .recipes-title span {
  cursor: pointer;
}
.recipes-container .recipes-title span img {
  width: 20px;
}
.recipes-container .recipes-restrictions {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  width: 80vw;
  max-width: 400px;
  font-size: 0.7rem;
  margin: 10px 0 15px 0;
  cursor: pointer;
  flex-wrap: wrap;
}
.recipes-container .recipes-restrictions span {
  border-radius: 12px;
  border: 1px solid #000000;
  padding: 3px 10px;
}
.recipes-container .recipes-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  max-width: 400px;
  margin-bottom: 20px;
}
.recipes-container .recipes-list .crate-recipe-btn-wrapper {
  display: flex;
  justify-content: center;
  /* gap: 20px; */
  align-items: center;
  border-radius: 4px;
  background: #b7dfeb;
  height: 50px;
  width: 90vw;
  padding: 0 50px;
  max-width: 600px;
  cursor: pointer;
  margin-bottom: 10px;
}
.recipes-container .recipes-list .crate-recipe-btn-wrapper span {
  font-size: 0.8rem;
  color: #000000;
  margin-right: 10px;
}
.recipes-container .recipes-list .crate-recipe-btn-wrapper span img {
  width: 20px;
  height: auto;
}

.recipes-container .show-hide-recipes {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100vw;
  max-width: 320px;
  cursor: pointer;
  margin: 10px 0 20px 0;
}
.recipes-container .bottom-separator {
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 3px dotted #000000;
  max-width: 300px;
  margin: 0 auto 20px auto;
}

/* Desktop */
.recipes-container-desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
}
.recipes-container-desktop .recipes-desktop-top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 5%;
  margin-top: 40px;
}
.recipes-container-desktop .title-wrapper {
  display: flex;
  /* flex: 1;
  flex-direction: column; */
  justify-content: center;
}
.recipes-container-desktop .title-wrapper .title {
  font-weight: 500;
  font-size: 1.8rem;
}
/* .recipes-container-desktop .title-wrapper .allergies {
  display: flex;
  
  gap: 7px;
  margin-top: 10px;
}
.recipes-container-desktop .title-wrapper .allergies .restriction {
  border-radius: 12px;
  border: 1px solid #000000;
  padding: 3px 10px;
  font-size: 0.8rem;
} */

.recipes-container-desktop .favAndSearch {
  display: flex;
  /* justify-content: center; */
  gap: 20px;
  align-items: center;
}
.recipes-container-desktop .favAndSearch .home-btn,
.recipes-container-desktop .favAndSearch .favorite,
.recipes-container-desktop .favAndSearch .search {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.recipes-container-desktop .favAndSearch .favorite img,
.recipes-container-desktop .favAndSearch .search img {
  width: 20px;
  height: auto;
}
.recipes-container-desktop .recipes-desktop-main,
.recipes-container-desktop .more-recipes {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: minmax(200px, auto);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  width: 100%;
  padding: 20px 5%;
  cursor: pointer;
  margin-bottom: 100px;
}

.recipes-container-desktop .recipes-desktop-main .create-recipe-btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 270px;
  border-radius: 4px;
  background: #b7dfeb;
}

.recipes-container-desktop
  .recipes-desktop-main
  .create-recipe-btn-wrapper
  span
  img {
  width: 40px;
  height: auto;
}

.recipes-container-desktop
  .recipes-desktop-main
  .create-recipe-btn-wrapper
  span {
  font-weight: 500;

  font-size: 1rem;
}

.recipes-container-desktop .recipes-desktop-separator {
  width: 90%;
  border-bottom: 3px dotted #3c4964;
  margin: 20px auto;
}

.recipes-container-desktop .recipes-desktop-bottom {
  margin-bottom: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.recipes-container-desktop .recipes-desktop-bottom .title {
  font-size: 1.2rem;
  font-weight: 500;
  padding-inline-start: 5%;
  margin-bottom: 20px;
}
@media (min-width: 600px) {
  .recipes-container .recipes-list .crate-recipe-btn-wrapper {
    width: 560px;
  }
}
@media (max-width: 490px) {
  .recipes-container .recipes-title {
    gap: 10px;
    font-size: 1rem;
  }
  .recipes-container .recipes-title span img {
    width: 15px;
  }
}
@media (max-width: 350px) {
  .recipes-container .recipes-title {
    gap: 5px;
    font-size: 0.8rem;
  }
  /* .recipes-container .doted-separator,
  .recipes-container .recipes-restrictions,
  .recipes-container .recipes-list .crate-recipe-btn-wrapper,
  .recipes-container .bottom-separator {
    max-width: 235px;
  } */
}
