.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  cursor: pointer;
}

/* mobile profile image  */
.header .profile-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* mobile back btn  */
.header .back-btn-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.header .back-btn-mobile span {
  font-size: 1rem;
  margin-top: 1px;
}
.header .profile-image-wrapper .img-circle {
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #a2d2e2;
  position: relative;
}

.header .profile-image-wrapper img {
  width: 100%;
}

.header .profile-name,
.header .profile-edit .text {
  font-size: 0.6rem;
  margin-top: 5px;
}
.header .profile-edit .text {
  visibility: hidden;
}

.header .logo img {
  width: 30vw;
  max-width: 200px;
  margin-bottom: 15px;
}
.header .profile-image-wrapper .img-circle,
.header .profile-edit .img-circle {
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #a2d2e2;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .profile-image-wrapper img {
  width: 100%;
  height: 115%;
  object-fit: cover;
}

.header .profile-edit .img-circle.edit {
  margin-bottom: 15px;
}

.header .profile-edit .img-circle.edit .profile-edit-image {
  width: 20px;
  height: 20px;
}
@media (min-width: 260px) {
  .header {
    justify-content: center;
    gap: 20px;
  }

  .header .logo img {
    max-width: 210px;
    width: 40vw;
  }
}
@media (min-width: 350px) {
  .header {
    justify-content: center;
    gap: 40px;
  }
}

@media (min-width: 500px) {
  .header {
    justify-content: center;
    gap: 50px;
  }
}
