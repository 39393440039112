.header-container-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 20px; */
  height: 100px;
  border-bottom: 2px dotted #f8c13b;
  /* width: 100%; */
  width: 90%;
  /* padding: 0 20px; */
}

.header-container-desktop .header-desktop-left {
  width: 25vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}
.header-container-desktop .header-desktop-left .logo img {
  width: 50vw;
  max-width: 150px;
}
.header-container-desktop .header-desktop-left .change-profile {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.header-container-desktop
  .header-desktop-left
  .change-profile
  .profile-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.header-container-desktop
  .header-desktop-left
  .change-profile
  .profile-image-wrapper.active {
  opacity: 1;
}
.header-container-desktop
  .header-desktop-left
  .change-profile
  .profile-image-wrapper.inactive {
  opacity: 0.5;
}
.header-container-desktop
  .header-desktop-left
  .change-profile
  .profile-image-wrapper
  .img-circle {
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #a2d2e2;
  position: relative;
  width: 50px;
  height: 50px;
}
.header-container-desktop
  .header-desktop-left
  .change-profile
  .profile-image-wrapper
  .img-circle
  img {
  width: 40px;
  height: 100%;
}

.header-container-desktop
  .header-desktop-left
  .change-profile
  .profile-image-wrapper
  .profile-name {
  font-size: 0.5rem;
}

.header-container-desktop .header-desktop-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  cursor: pointer;
  font-size: 0.8rem;
}

.header-container-desktop .header-desktop-center .user-list,
.header-container-desktop .header-desktop-center .create-recipe-wrapper,
.header-container-desktop .header-desktop-center .create-article-wrapper {
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
}
.header-container-desktop .header-desktop-center .user-list:hover,
.header-container-desktop .header-desktop-center .create-recipe-wrapper:hover,
.header-container-desktop .header-desktop-center .create-article-wrapper:hover {
  color: #a2d2e2;
}

.header-container-desktop .header-desktop-center .user-list:hover .user-icon,
.header-container-desktop
  .header-desktop-center
  .create-recipe-wrapper:hover
  .create-recipe-icon,
.header-container-desktop
  .header-desktop-center
  .create-article-wrapper:hover
  .create-recipe-icon {
  fill: #a2d2e2;
}
.header-container-desktop .header-desktop-right {
  width: 35vw;
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  align-items: center;
  cursor: pointer;
}

.header-container-desktop .header-desktop-right .amount-of-notifications {
  border: 1px solid #000;
  border-radius: 50%;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
}

.header-container-desktop .header-desktop-right .profile-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header-container-desktop .header-desktop-right .profile-edit .img-circle {
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #a2d2e2;
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-container-desktop .header-desktop-right .profile-edit .img-circle img {
  width: 25px;
  padding: 5px;
}
@media (min-width: 801px) and (max-width: 1024px) {
  .header-container-desktop .header-desktop-right {
    gap: 10px;
    width: 30vw;
  }
}
