.article-panel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(243, 111, 33, 0) 10.21%,
    #50b848 100%
  );
}
.article-panel-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
}
.article-panel-main .title {
  margin-top: 50px;
  font-size: 1.5rem;
  font-weight: 500;
}

.article-panel-main .article-panel-select {
  margin: 30px 0;
}
.article-panel-main .panel-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 100px;
}
.article-panel-main .panel-content .form-wrapper {
}
.article-panel-main .panel-content .form-wrapper input,
.article-panel-main .panel-content .form-wrapper textarea,
.article-panel-main .panel-content .form-wrapper label,
.article-panel-main .panel-content .date-wrapper {
  width: 300px;
  background-color: #fff;
  height: 40px;
  padding-inline-start: 10px;
  cursor: pointer;
}

/*  for date input notification  */
.article-panel-main .panel-content .date-wrapper {
  height: 70px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.article-panel-main .panel-content .date-wrapper input {
  width: 100%;
  height: 40px;
  background-color: #fff;
  padding: 0 10px;
  cursor: pointer;
  border: none;
}
.article-panel-main .panel-content .date-wrapper input:focus {
  outline: none;
}
.article-panel-main .panel-content .date-wrapper input::placeholder {
  padding: 0 20px;
}
.article-panel-main .panel-content .date-wrapper span {
  padding: 0 0 10px 10px;
}
.article-panel-main .panel-content .form-wrapper input::placeholder,
.article-panel-main .panel-content .form-wrapper textarea::placeholder {
  color: #3f3d3d;
}
.article-panel-main .panel-content .form-wrapper textarea::placeholder {
  padding-top: 10px;
}
.article-panel-main .panel-content .form-wrapper textarea {
  height: 80px;
}
.article-panel-main .panel-content .form-wrapper label {
  display: flex;
  justify-content: baseline;
  align-items: center;
  gap: 30px;
}
.article-panel-main .panel-content .form-wrapper label.article-secondary-img {
  gap: 33px;
}
.article-panel-main .panel-content .submit {
  margin: 30px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.article-panel-main .panel-content .submit button {
  width: 200px;
  height: 40px;
  background-color: #f68222;
  border: none;
  border-radius: 50px;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.article-panel-main .panel-content .error {
  text-align: center;
  color: red;
  font-size: 1.3rem;
  font-weight: 400;
  width: 100%;
}
